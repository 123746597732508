import { getCurrentProject } from './../services/projectService';

export const setCurrentProject = () => {
    return async (dispatch) => {
        try {
            const { status, data } = await getCurrentProject();

            // if user have valid cookie
            if (status === 200) {
                if (data?.permissions?.access)
                    await dispatch({
                        type: 'SET_PROJECT',
                        payload: data,
                    });
                else
                    window.location.replace(
                        process.env.REACT_APP_MODELIC_REDIRECT
                    );
            } else throw new Error('wrong status');
        } catch {}
    };
};

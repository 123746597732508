export const setMarkupsToolbar = (toolbar) => {
    return async (dispatch, getState) => {
        const toolbars = { ...getState().toolbars };
        const updatedToolbars = { ...toolbars };
        updatedToolbars.markupsToolbar = toolbar;
        await dispatch({
            type: 'SET_TOOLBARS',
            payload: { ...updatedToolbars },
        });
    };
};

export const setCloseToolbar = (toolbar) => {
    return async (dispatch, getState) => {
        const toolbars = { ...getState().toolbars };
        const updatedToolbars = { ...toolbars };
        updatedToolbars.closeToolbar = toolbar;
        await dispatch({
            type: 'SET_TOOLBARS',
            payload: { ...updatedToolbars },
        });
    };
};

export const removeToolbars = () => {
    return async (dispatch, getState) => {
        const toolbars = getState().toolbars;
        const viewer = getState().viewer;

        let markupExt = viewer?.getExtension('Autodesk.Viewing.MarkupsCore');
        markupExt?.leaveEditMode();
        markupExt?.hide();

        toolbars.markupsToolbar?.setVisible(false);
        toolbars.closeToolbar?.setVisible(false);

        await dispatch({
            type: 'SET_TOOLBARS',
            payload: {},
        });
    };
};

export const IcProject = (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M15.75 9H8.25M15.75 15H8.25M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const IcProfile = (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M20.59 22C20.59 18.13 16.74 15 12 15C7.26 15 3.41 18.13 3.41 22M12 12C13.3261 12 14.5979 11.4732 15.5355 10.5355C16.4732 9.59785 17 8.32608 17 7C17 5.67392 16.4732 4.40215 15.5355 3.46447C14.5979 2.52678 13.3261 2 12 2C10.6739 2 9.40215 2.52678 8.46447 3.46447C7.52679 4.40215 7 5.67392 7 7C7 8.32608 7.52679 9.59785 8.46447 10.5355C9.40215 11.4732 10.6739 12 12 12Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const IcTicket = (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8.5 10.4997H15.5M7 18.4297H11L15.45 21.3897C15.6002 21.4898 15.7747 21.5473 15.955 21.556C16.1353 21.5646 16.3145 21.5242 16.4736 21.439C16.6328 21.3538 16.7658 21.227 16.8585 21.0722C16.9512 20.9173 17.0001 20.7402 17 20.5597V18.4297C20 18.4297 22 16.4297 22 13.4297V7.42969C22 4.42969 20 2.42969 17 2.42969H7C4 2.42969 2 4.42969 2 7.42969V13.4297C2 16.4297 4 18.4297 7 18.4297Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const IcHome = (
    <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M9 13.5V11.25"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M7.55245 2.11586L2.35495 6.27836C1.76995 6.74336 1.39495 7.72586 1.52245 8.46086L2.51995 14.4309C2.69995 15.4959 3.71995 16.3584 4.79995 16.3584H13.2C14.2725 16.3584 15.3 15.4884 15.48 14.4309L16.4775 8.46086C16.5975 7.72586 16.2225 6.74336 15.645 6.27836L10.4475 2.12336C9.64495 1.47836 8.34745 1.47836 7.55245 2.11586Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const IcSubMenu = (
    <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.64088 4.99975L3.05539 2.41427C2.66486 2.02374 2.58572 1.46972 2.87861 1.17683C3.17151 0.883935 3.72552 0.963081 4.11605 1.35361L6.94448 4.18203C7.18777 4.42532 7.31021 4.73207 7.29713 4.9996C7.31031 5.2672 7.18787 5.57408 6.94449 5.81746L4.11606 8.64589C3.72554 9.03641 3.17152 9.11556 2.87863 8.82266C2.58573 8.52977 2.66488 7.97575 3.0554 7.58523L5.64088 4.99975Z"
            fill="currentColor"
        />
    </svg>
);

export const IcEye = (
    <svg
        className="group-hover:hidden"
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M13.4833 10.0009C13.4833 11.6509 12.15 12.9842 10.5 12.9842C8.85 12.9842 7.51666 11.6509 7.51666 10.0009C7.51666 8.35091 8.85 7.01758 10.5 7.01758C12.15 7.01758 13.4833 8.35091 13.4833 10.0009Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M10.5 16.8913C13.4417 16.8913 16.1833 15.1579 18.0917 12.1579C18.8417 10.9829 18.8417 9.00794 18.0917 7.83294C16.1833 4.83294 13.4417 3.09961 10.5 3.09961C7.55832 3.09961 4.81666 4.83294 2.90833 7.83294C2.15833 9.00794 2.15833 10.9829 2.90833 12.1579C4.81666 15.1579 7.55832 16.8913 10.5 16.8913Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const IcEyeFill = (
    <svg
        className="hidden group-hover:block"
        width="21"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M21.25 9.14969C18.94 5.51969 15.56 3.42969 12 3.42969C10.22 3.42969 8.49 3.94969 6.91 4.91969C5.33 5.89969 3.91 7.32969 2.75 9.14969C1.75 10.7197 1.75 13.2697 2.75 14.8397C5.06 18.4797 8.44 20.5597 12 20.5597C13.78 20.5597 15.51 20.0397 17.09 19.0697C18.67 18.0897 20.09 16.6597 21.25 14.8397C22.25 13.2797 22.25 10.7197 21.25 9.14969ZM12 16.0397C9.76 16.0397 7.96 14.2297 7.96 11.9997C7.96 9.76969 9.76 7.95969 12 7.95969C14.24 7.95969 16.04 9.76969 16.04 11.9997C16.04 14.2297 14.24 16.0397 12 16.0397Z"
            fill="currentColor"
        />
        <path
            d="M11.9981 9.14062C11.2409 9.14062 10.5147 9.44142 9.97928 9.97684C9.44386 10.5123 9.14307 11.2384 9.14307 11.9956C9.14307 12.7528 9.44386 13.479 9.97928 14.0144C10.5147 14.5498 11.2409 14.8506 11.9981 14.8506C13.5681 14.8506 14.8581 13.5706 14.8581 12.0006C14.8581 10.4306 13.5681 9.14062 11.9981 9.14062Z"
            fill="currentColor"
        />
    </svg>
);

export const IcModelFill = (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M20.098 6.94C20.098 7.48 19.808 7.97 19.348 8.22L17.608 9.16L16.128 9.95L13.058 11.61C12.728 11.79 12.368 11.88 11.998 11.88C11.628 11.88 11.268 11.79 10.938 11.61L4.64799 8.22C4.18799 7.97 3.89799 7.48 3.89799 6.94C3.89799 6.4 4.18799 5.91 4.64799 5.66L6.61799 4.6L8.18799 3.75L10.938 2.27C11.598 1.91 12.398 1.91 13.058 2.27L19.348 5.66C19.808 5.91 20.098 6.4 20.098 6.94ZM9.89899 12.79L4.04899 9.87C3.59899 9.64 3.07899 9.67 2.64899 9.93C2.21899 10.19 1.96899 10.65 1.96899 11.15V16.68C1.96899 17.64 2.49899 18.5 3.35899 18.93L9.20899 21.85C9.40899 21.95 9.62899 22 9.84899 22C10.109 22 10.369 21.93 10.599 21.78C11.029 21.52 11.279 21.06 11.279 20.56V15.03C11.289 14.08 10.759 13.22 9.89899 12.79ZM22.03 11.15V16.68C22.03 17.63 21.5 18.49 20.64 18.92L14.79 21.85C14.5702 21.9593 14.326 22.0103 14.0808 21.998C13.8357 21.9858 13.5978 21.9107 13.39 21.78C12.97 21.52 12.71 21.06 12.71 20.56V15.04C12.71 14.08 13.24 13.22 14.1 12.79L16.25 11.72L17.75 10.97L19.95 9.87C20.4 9.64 20.92 9.66 21.35 9.93C21.77 10.19 22.03 10.65 22.03 11.15Z"
            fill="currentColor"
        />
        <path
            d="M17.6111 9.16L16.1311 9.95L6.62109 4.6L8.19109 3.75L17.3711 8.93C17.4711 8.99 17.5511 9.07 17.6111 9.16ZM17.7501 10.969V13.239C17.7501 13.649 17.4101 13.989 17.0001 13.989C16.5901 13.989 16.2501 13.649 16.2501 13.239V11.719L17.7501 10.969Z"
            fill="currentColor"
        />
    </svg>
);

export const IcPointCloud = (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M18.6701 18.67L20.8901 17.56V14.78M14.2201 9.78L12.0001 10.89M12.0001 10.89L9.78011 9.78M12.0001 10.89V13.67M20.8901 6.44L18.6701 7.55M20.8901 6.44L18.6701 5.33M20.8901 6.44V9.22M3.11011 6.44L5.33011 5.33M3.11011 6.44L5.33011 7.55M3.11011 6.44V9.22M12.0001 22L9.78011 20.89M12.0001 22L14.2201 20.89M12.0001 22V19.22M14.2201 3.11L12.0001 2L9.78011 3.11H14.2201ZM5.33011 18.67L3.11011 17.56V14.78L5.33011 18.67Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const IcSignOut = (
    <svg
        width="22"
        height="18"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M7.9 6.56023C8.21 2.96023 10.06 1.49023 14.11 1.49023H14.24C18.71 1.49023 20.5 3.28023 20.5 7.75023V14.2702C20.5 18.7402 18.71 20.5302 14.24 20.5302H14.11C10.09 20.5302 8.24 19.0802 7.91 15.5402M14 11.0002H2.62M4.85 7.65023L1.5 11.0002L4.85 14.3502"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const IcModel = (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M3.17004 7.43945L12 12.5495L20.77 7.46945M12 21.6095V12.5395"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M9.93001 2.4793L4.59001 5.4393C3.38001 6.1093 2.39001 7.7893 2.39001 9.1693V14.8193C2.39001 16.1993 3.38001 17.8793 4.59001 18.5493L9.93001 21.5193C11.07 22.1493 12.94 22.1493 14.08 21.5193L19.42 18.5493C20.63 17.8793 21.62 16.1993 21.62 14.8193V9.1693C21.62 7.7893 20.63 6.1093 19.42 5.4393L14.08 2.4693C12.93 1.8393 11.07 1.8393 9.93001 2.4793Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const IcView = (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M22 10H18C15 10 14 9 14 6V2L22 10Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const IcViewFill = (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M20.5 10.19H17.61C15.24 10.19 13.31 8.26 13.31 5.89V3C13.31 2.45 12.86 2 12.31 2H8.07C4.99 2 2.5 4 2.5 7.57V16.43C2.5 20 4.99 22 8.07 22H15.93C19.01 22 21.5 20 21.5 16.43V11.19C21.5 10.64 21.05 10.19 20.5 10.19Z"
            fill="currentColor"
        />
        <path
            d="M15.8002 2.21048C15.3902 1.80048 14.6802 2.08048 14.6802 2.65048V6.14048C14.6802 7.60048 15.9202 8.81048 17.4302 8.81048C18.3802 8.82048 19.7002 8.82048 20.8302 8.82048C21.4002 8.82048 21.7002 8.15048 21.3002 7.75048C19.8602 6.30048 17.2802 3.69048 15.8002 2.21048Z"
            fill="currentColor"
        />
    </svg>
);

export const IcIssue = (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M16 2H8C4 2 2 4 2 8V21C2 21.55 2.45 22 3 22H16C20 22 22 20 22 16V8C22 4 20 2 16 2Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8.5 12H15.5"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const IcIssueFill = (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M16 2H8C4 2 2 4 2 8V21C2 21.55 2.45 22 3 22H16C20 22 22 20 22 16V8C22 4 20 2 16 2ZM15.5 12.75H8.5C8.09 12.75 7.75 12.41 7.75 12C7.75 11.59 8.09 11.25 8.5 11.25H15.5C15.91 11.25 16.25 11.59 16.25 12C16.25 12.41 15.91 12.75 15.5 12.75Z"
            fill="currentColor"
        />
    </svg>
);

export const IcFilter = (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M10.93 2.09961L6.00002 9.99961M5.40002 2.09961H18.6C19.7 2.09961 20.6 2.99961 20.6 4.09961V6.29961C20.6 7.09961 20.1 8.09961 19.6 8.59961L15.3 12.3996C14.7 12.8996 14.3 13.8996 14.3 14.6996V18.9996C14.3 19.5996 13.9 20.3996 13.4 20.6996L12 21.5996C10.7 22.3996 8.90002 21.4996 8.90002 19.8996V14.5996C8.90002 13.8996 8.50002 12.9996 8.10002 12.4996L4.30002 8.49961C3.80002 7.99961 3.40002 7.09961 3.40002 6.49961V4.19961C3.40002 2.99961 4.30002 2.09961 5.40002 2.09961Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const IcFilterFill = (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M20.5999 4.10156V6.30156C20.5999 7.10156 20.0999 8.10156 19.5999 8.60156L15.2999 12.4016C14.6999 12.9016 14.2999 13.9016 14.2999 14.7016V19.0016C14.2999 19.6016 13.8999 20.4016 13.3999 20.7016L11.9999 21.6016C10.6999 22.4016 8.8999 21.5016 8.8999 19.9016V14.6016C8.8999 13.9016 8.4999 13.0016 8.0999 12.5016L7.6299 12.0116C7.47657 11.8508 7.38145 11.6433 7.35971 11.4222C7.33796 11.2011 7.39083 10.9791 7.5099 10.7916L12.6299 2.57156C12.8099 2.28156 13.1299 2.10156 13.4799 2.10156H18.5999C19.6999 2.10156 20.5999 3.00156 20.5999 4.10156ZM10.3499 3.63156L6.7999 9.32156C6.4599 9.87156 5.6799 9.95156 5.2299 9.48156L4.2999 8.50156C3.7999 8.00156 3.3999 7.10156 3.3999 6.50156V4.20156C3.3999 3.00156 4.2999 2.10156 5.3999 2.10156H9.4999C10.2799 2.10156 10.7599 2.96156 10.3499 3.63156Z"
            fill="currentColor"
        />
    </svg>
);

export const IcRefresh = (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M22 12C22 17.52 17.52 22 12 22C6.48 22 3.11 16.44 3.11 16.44M3.11 16.44H7.63M3.11 16.44V21.44M2 12C2 6.48 6.44 2 12 2C18.67 2 22 7.56 22 7.56M22 7.56V2.56M22 7.56H17.56"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const IcCloseToolbar = (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M21.97 15V9C21.97 4 19.97 2 14.97 2H8.96997C3.96997 2 1.96997 4 1.96997 9V15C1.96997 20 3.96997 22 8.96997 22H14.97C19.97 22 21.97 20 21.97 15Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M7.96997 2V22"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M14.97 9.43945L12.41 11.9995L14.97 14.5595"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const IcClose = (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M6.46447 6.46424L13.5355 13.5353M6.46447 13.5353L13.5355 6.46424"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const IcError = (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M7.99967 14.6663C11.6663 14.6663 14.6663 11.6663 14.6663 7.99967C14.6663 4.33301 11.6663 1.33301 7.99967 1.33301C4.33301 1.33301 1.33301 4.33301 1.33301 7.99967C1.33301 11.6663 4.33301 14.6663 7.99967 14.6663Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8 5.33301V8.66634"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M7.99609 10.667H8.00208"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

// cdn
export const IcUpload = (
    <svg
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M7.49967 14.667V9.66699M7.49967 9.66699L5.83301 11.3337M7.49967 9.66699L9.16634 11.3337"
            stroke="currentColor"
            strokeWidth="0.760274"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M18.3327 8.83366V13.0003C18.3327 17.167 16.666 18.8337 12.4993 18.8337H7.49935C3.33268 18.8337 1.66602 17.167 1.66602 13.0003V8.00033C1.66602 3.83366 3.33268 2.16699 7.49935 2.16699H11.666"
            stroke="currentColor"
            strokeWidth="0.760274"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M18.3327 8.83366H14.9993C12.4993 8.83366 11.666 8.00033 11.666 5.50033V2.16699L18.3327 8.83366Z"
            stroke="currentColor"
            strokeWidth="0.760274"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const IcDownload = (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_1011_6522)">
            <path
                d="M15.5374 14.3351C15.9666 14.3361 16.3917 14.2521 16.7882 14.0878C17.1845 13.9235 17.5443 13.6824 17.8469 13.3784C18.4631 12.7621 18.8036 11.9384 18.8036 11.0672C18.8036 9.58612 17.8053 8.28818 16.3778 7.90364L16.3756 7.90307L15.7807 7.74567L15.5637 7.17167L15.5636 7.17135C15.3539 6.61805 15.0614 6.09625 14.6931 5.62549C14.3305 5.16071 13.9019 4.75155 13.4172 4.41054L13.4171 4.41047C12.4151 3.70596 11.2343 3.33287 10.0045 3.33287C8.77465 3.33287 7.59379 3.70596 6.59187 4.41047L6.59175 4.41055C6.1089 4.75027 5.679 5.15959 5.31603 5.62522C4.94817 6.09539 4.65533 6.61494 4.44537 7.17137C4.44529 7.17159 4.4452 7.17181 4.44512 7.17203L4.22681 7.74686L3.63033 7.90267L3.63033 7.90267L3.62844 7.90317C2.94185 8.08538 2.32684 8.49337 1.88777 9.05338L1.88698 9.05439C1.4583 9.60405 1.22041 10.2593 1.19672 10.9542L1.19669 10.955C1.16777 11.8438 1.49674 12.7034 2.11674 13.3419L2.11676 13.3419C2.73746 13.981 3.57433 14.3351 4.46205 14.3351H5.03571V15.0315H4.46205C2.27407 15.0315 0.5 13.2578 0.5 11.0672C0.5 9.2286 1.7543 7.67984 3.45286 7.23389L3.70207 7.16846L3.79342 6.92754C4.74456 4.41884 7.16779 2.63867 10.0045 2.63867C12.8409 2.63867 15.2643 4.42083 16.2155 6.92977L16.3067 7.1702L16.5553 7.23591C18.2496 7.68377 19.5 9.23037 19.5 11.0672C19.5 13.2578 17.7259 15.0315 15.5379 15.0315H14.9643M15.5374 14.3351C15.5371 14.3351 15.5369 14.3351 15.5366 14.3351L15.5379 13.8351V14.3351H15.5374ZM15.5374 14.3351H14.9643M14.9643 14.3351V14.0137C14.9643 14.1916 14.8208 14.3351 14.6429 14.3351H14.9643ZM14.9643 14.3351V15.0315M14.9643 15.0315H14.6429C14.8208 15.0315 14.9643 15.175 14.9643 15.353V15.0315ZM10.3461 14.3369V14.8369H10.8461H11.8358L10.0001 17.1594L8.1644 14.8369H9.14965H9.64965V14.3369V9.24988H10.3461V14.3369Z"
                fill="currentColor"
                stroke="currentColor"
            />
        </g>
        <defs>
            <clipPath id="clip0_1011_6522">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export const IcDelete = (
    <svg
        className="group-hover:hidden"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M17.5 4.98307C14.725 4.70807 11.9333 4.56641 9.15 4.56641C7.5 4.56641 5.85 4.64974 4.2 4.81641L2.5 4.98307"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M7.08301 4.14199L7.26634 3.05033C7.39967 2.25866 7.49967 1.66699 8.90801 1.66699H11.0913C12.4997 1.66699 12.608 2.29199 12.733 3.05866L12.9163 4.14199"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M15.7087 7.61621L15.167 16.0079C15.0753 17.3162 15.0003 18.3329 12.6753 18.3329H7.32533C5.00033 18.3329 4.92533 17.3162 4.83366 16.0079L4.29199 7.61621"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8.6084 13.75H11.3834"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M7.91699 10.417H12.0837"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const IcDeleteHover = (
    <svg
        className="hidden group-hover:block"
        width="20"
        height="22"
        viewBox="0 0 20 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M17.7076 7.65157C15.2262 6.3793 12.6804 5.22499 10.0905 4.20555C8.55514 3.60122 6.98928 3.07442 5.39289 2.62517L3.74998 2.15761"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8.32323 3.05301L8.89366 2.10436C9.30769 1.41654 9.61745 0.90261 10.9279 1.41843L12.9595 2.21811C14.27 2.73393 14.1419 3.35518 13.9774 4.11435L13.7512 5.18956"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M15.7083 9.61667L15.1667 18.0083C15.075 19.3167 15 20.3333 12.675 20.3333H7.325C5 20.3333 4.925 19.3167 4.83333 18.0083L4.29166 9.61667"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8.60834 15.75H11.3833"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M7.91666 12.4167H12.0833"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const IcDeleteFill = (
    <svg
        className="hidden group-hover:block"
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M21.0699 5.23C19.4599 5.07 17.8499 4.95 16.2299 4.86V4.85L16.0099 3.55C15.8599 2.63 15.6399 1.25 13.2999 1.25H10.6799C8.34991 1.25 8.12991 2.57 7.96991 3.54L7.75991 4.82C6.82991 4.88 5.89991 4.94 4.96991 5.03L2.92991 5.23C2.50991 5.27 2.20991 5.64 2.24991 6.05C2.28991 6.46 2.64991 6.76 3.06991 6.72L5.10991 6.52C10.3499 6 15.6299 6.2 20.9299 6.73H21.0099C21.3899 6.73 21.7199 6.44 21.7599 6.05C21.775 5.85024 21.7112 5.65253 21.5822 5.49925C21.4532 5.34596 21.2693 5.24931 21.0699 5.23ZM19.2299 8.14C18.9899 7.89 18.6599 7.75 18.3199 7.75H5.67991C5.33991 7.75 4.99991 7.89 4.76991 8.14C4.53991 8.39 4.40991 8.73 4.42991 9.08L5.04991 19.34C5.15991 20.86 5.29991 22.76 8.78991 22.76H15.2099C18.6999 22.76 18.8399 20.87 18.9499 19.34L19.5699 9.09C19.5899 8.73 19.4599 8.39 19.2299 8.14ZM13.6599 17.75H10.3299C9.91991 17.75 9.57991 17.41 9.57991 17C9.57991 16.59 9.91991 16.25 10.3299 16.25H13.6599C14.0699 16.25 14.4099 16.59 14.4099 17C14.4099 17.41 14.0699 17.75 13.6599 17.75ZM14.4999 13.75H9.49991C9.08991 13.75 8.74991 13.41 8.74991 13C8.74991 12.59 9.08991 12.25 9.49991 12.25H14.4999C14.9099 12.25 15.2499 12.59 15.2499 13C15.2499 13.41 14.9099 13.75 14.4999 13.75Z"
            fill="currentColor"
        />
    </svg>
);

// file types
export const IcDoc = (
    <svg
        width="30"
        height="36"
        viewBox="0 0 201 240"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g opacity="0.3">
            <path
                opacity="0.3"
                d="M139.68 49.25C134.213 49.2447 128.971 47.0704 125.105 43.2046C121.239 39.3387 119.065 34.097 119.06 28.6299V1.85854e-06H26.9298C23.3951 -0.00131167 19.8947 0.693646 16.6287 2.04541C13.3626 3.39718 10.395 5.37922 7.89514 7.87818C5.39526 10.3771 3.41218 13.3441 2.0592 16.6096C0.706225 19.8751 0.00976538 23.3752 0.00976562 26.9099V189.13C0.00976538 192.665 0.706225 196.165 2.0592 199.43C3.41218 202.696 5.39526 205.663 7.89514 208.162C10.395 210.661 13.3626 212.643 16.6287 213.994C19.8947 215.346 23.3951 216.041 26.9298 216.04H137.53C141.064 216.04 144.563 215.344 147.828 213.992C151.093 212.639 154.059 210.657 156.558 208.158C159.057 205.659 161.039 202.693 162.391 199.428C163.744 196.163 164.44 192.664 164.44 189.13V49.25H139.68Z"
                fill="#078DEE"
            />
        </g>
        <path
            d="M164.44 49.25H139.68C134.212 49.2447 128.971 47.0704 125.105 43.2046C121.239 39.3387 119.065 34.097 119.06 28.6299V0L164.44 49.25Z"
            fill="#078DEE"
        />
        <path
            d="M130.61 83.7302H27.3699C26.378 83.7302 25.4267 83.3362 24.7254 82.6348C24.024 81.9334 23.6299 80.9821 23.6299 79.9902C23.6286 79.4982 23.7244 79.0108 23.9118 78.5559C24.0991 78.101 24.3744 77.6875 24.7218 77.3391C25.0692 76.9908 25.482 76.7145 25.9364 76.5259C26.3908 76.3373 26.8779 76.2402 27.3699 76.2402H130.61C131.605 76.2402 132.558 76.6354 133.262 77.3386C133.965 78.0419 134.36 78.9957 134.36 79.9902C134.357 80.9831 133.961 81.9344 133.258 82.6355C132.555 83.3366 131.603 83.7302 130.61 83.7302Z"
            fill="#078DEE"
        />
        <path
            d="M130.61 106.099H27.3699C26.378 106.099 25.4267 105.705 24.7254 105.004C24.024 104.303 23.6299 103.351 23.6299 102.359C23.6286 101.867 23.7244 101.38 23.9118 100.925C24.0991 100.47 24.3744 100.057 24.7218 99.7083C25.0692 99.3599 25.482 99.0836 25.9364 98.895C26.3908 98.7064 26.8779 98.6094 27.3699 98.6094H130.61C131.605 98.6094 132.558 99.0043 133.262 99.7075C133.965 100.411 134.36 101.365 134.36 102.359C134.357 103.352 133.961 104.303 133.258 105.004C132.555 105.705 131.603 106.099 130.61 106.099Z"
            fill="#078DEE"
        />
        <path
            d="M130.61 128.47H27.3699C26.8779 128.47 26.3908 128.373 25.9364 128.185C25.482 127.996 25.0692 127.72 24.7218 127.372C24.3744 127.023 24.0991 126.61 23.9118 126.155C23.7244 125.7 23.6286 125.212 23.6299 124.72C23.6299 123.729 24.024 122.777 24.7254 122.076C25.4267 121.375 26.378 120.98 27.3699 120.98H130.61C131.603 120.98 132.555 121.374 133.258 122.075C133.961 122.776 134.357 123.728 134.36 124.72C134.36 125.715 133.965 126.669 133.262 127.372C132.558 128.075 131.605 128.47 130.61 128.47Z"
            fill="#078DEE"
        />
        <path
            d="M89.8899 150.84H27.3699C26.8779 150.84 26.3908 150.743 25.9364 150.554C25.482 150.365 25.0692 150.089 24.7218 149.74C24.3744 149.392 24.0991 148.979 23.9118 148.524C23.7244 148.069 23.6286 147.582 23.6299 147.09C23.6299 146.098 24.024 145.146 24.7254 144.445C25.4267 143.743 26.378 143.35 27.3699 143.35H89.8899C90.8818 143.35 91.8332 143.743 92.5346 144.445C93.2359 145.146 93.6299 146.098 93.6299 147.09C93.6312 147.582 93.5355 148.069 93.3482 148.524C93.1608 148.979 92.8855 149.392 92.5381 149.74C92.1907 150.089 91.7779 150.365 91.3235 150.554C90.8691 150.743 90.3819 150.84 89.8899 150.84Z"
            fill="#078DEE"
        />
        <path
            d="M183.959 170.609H58.9193C50.0496 170.609 42.8594 177.8 42.8594 186.669V223.949C42.8594 232.819 50.0496 240.009 58.9193 240.009H183.959C192.829 240.009 200.019 232.819 200.019 223.949V186.669C200.019 177.8 192.829 170.609 183.959 170.609Z"
            fill="#078DEE"
        />
        <path
            d="M76.2302 213.379V220.649H68.7002V213.379H76.2302Z"
            fill="#F9FAFB"
        />
        <path
            d="M105.17 213.399C103.918 215.673 102.026 217.53 99.7299 218.74C97.1453 220.061 94.2719 220.718 91.3698 220.649H80.0098V190.58H91.3698C94.274 190.508 97.1515 191.15 99.7498 192.45C102.037 193.637 103.923 195.474 105.17 197.73C106.463 200.13 107.114 202.824 107.06 205.55C107.111 208.285 106.461 210.987 105.17 213.399ZM97.3499 211.889C98.1628 211.055 98.7925 210.06 99.1989 208.969C99.6052 207.877 99.7793 206.712 99.7098 205.55C99.7798 204.388 99.6059 203.225 99.1995 202.135C98.793 201.045 98.163 200.052 97.3499 199.22C95.5279 197.622 93.1483 196.81 90.7299 196.96H87.2998V214.139H90.7397C93.1528 214.285 95.5267 213.477 97.3499 211.889Z"
            fill="#F9FAFB"
        />
        <path
            d="M133.2 192.091C135.488 193.39 137.379 195.288 138.67 197.581C140.022 200.002 140.712 202.737 140.67 205.51C140.715 208.29 140.025 211.033 138.67 213.46C137.374 215.762 135.476 217.667 133.18 218.97C130.842 220.305 128.191 220.996 125.5 220.97C122.808 220.994 120.158 220.304 117.82 218.97C115.523 217.669 113.628 215.764 112.34 213.46C110.979 211.035 110.289 208.291 110.34 205.51C110.288 202.736 110.978 199.998 112.34 197.581C113.635 195.287 115.529 193.39 117.82 192.091C120.158 190.757 122.808 190.067 125.5 190.091C128.198 190.065 130.855 190.755 133.2 192.091ZM119.8 199.19C118.324 200.955 117.574 203.214 117.7 205.51C117.574 207.798 118.325 210.048 119.8 211.8C120.518 212.581 121.399 213.196 122.38 213.6C123.362 214.004 124.419 214.189 125.48 214.14C126.537 214.186 127.59 214 128.568 213.596C129.546 213.191 130.423 212.579 131.14 211.8C132.622 210.051 133.38 207.801 133.26 205.51C133.38 203.224 132.629 200.976 131.16 199.22C130.441 198.439 129.561 197.824 128.579 197.42C127.598 197.016 126.54 196.831 125.48 196.88C124.422 196.832 123.367 197.013 122.386 197.412C121.405 197.811 120.523 198.418 119.8 199.19Z"
            fill="#F9FAFB"
        />
        <path
            d="M167.99 193.3C170.526 195.363 172.251 198.257 172.86 201.47H165.16C164.64 200.138 163.731 198.995 162.55 198.19C161.305 197.379 159.845 196.965 158.36 197C157.38 196.968 156.405 197.163 155.512 197.569C154.619 197.975 153.831 198.581 153.21 199.34C151.818 201.121 151.108 203.341 151.21 205.6C151.108 207.849 151.818 210.06 153.21 211.83C153.836 212.581 154.626 213.18 155.518 213.58C156.41 213.98 157.383 214.172 158.36 214.14C159.845 214.179 161.307 213.764 162.55 212.95C163.724 212.159 164.634 211.034 165.16 209.72H172.86C172.241 212.923 170.518 215.807 167.99 217.87C165.27 219.947 161.91 221.008 158.49 220.87C155.796 220.927 153.136 220.256 150.79 218.93C148.595 217.654 146.814 215.772 145.66 213.51C144.42 211.028 143.803 208.283 143.86 205.51C143.804 202.737 144.421 199.991 145.66 197.51C146.814 195.248 148.595 193.366 150.79 192.09C153.136 190.763 155.796 190.093 158.49 190.15C161.927 190.059 165.288 191.173 167.99 193.3Z"
            fill="#F9FAFB"
        />
    </svg>
);

export const IcTickSquare = (
    <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8.00033 18.3337H13.0003C17.167 18.3337 18.8337 16.667 18.8337 12.5003V7.50033C18.8337 3.33366 17.167 1.66699 13.0003 1.66699H8.00033C3.83366 1.66699 2.16699 3.33366 2.16699 7.50033V12.5003C2.16699 16.667 3.83366 18.3337 8.00033 18.3337Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M6.95801 9.99993L9.31634 12.3583L14.0413 7.6416"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const IcObjectName = (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M16.6666 6.87435V14.9993C16.6666 17.4993 15.1749 18.3327 13.3333 18.3327H6.66659C4.82492 18.3327 3.33325 17.4993 3.33325 14.9993V6.87435C3.33325 4.16602 4.82492 3.54102 6.66659 3.54102C6.66659 4.05768 6.87492 4.52435 7.21659 4.86602C7.55825 5.20768 8.02492 5.41602 8.54159 5.41602H11.4583C12.4916 5.41602 13.3333 4.57435 13.3333 3.54102C15.1749 3.54102 16.6666 4.16602 16.6666 6.87435Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M6.66675 10.8327H10.0001M6.66675 14.166H13.3334M13.3334 3.54102C13.3334 4.57435 12.4917 5.41602 11.4584 5.41602H8.54175C8.02508 5.41602 7.55841 5.20768 7.21675 4.86602C6.87508 4.52435 6.66675 4.05768 6.66675 3.54102C6.66675 2.50768 7.50841 1.66602 8.54175 1.66602H11.4584C11.9751 1.66602 12.4417 1.87435 12.7834 2.21602C13.1251 2.55768 13.3334 3.02435 13.3334 3.54102Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const IcObjectDate = (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M6.66667 1.66602V4.16602M13.3333 1.66602V4.16602M2.91667 7.57435H17.0833M17.5 7.08268V14.166C17.5 16.666 16.25 18.3327 13.3333 18.3327H6.66667C3.75 18.3327 2.5 16.666 2.5 14.166V7.08268C2.5 4.58268 3.75 2.91602 6.66667 2.91602H13.3333C16.25 2.91602 17.5 4.58268 17.5 7.08268Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M13.0791 11.416H13.0866M13.0791 13.916H13.0866M9.99579 11.416H10.0041M9.99579 13.916H10.0041M6.91162 11.416H6.91995M6.91162 13.916H6.91995"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const IcObjectVersion = (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_425_5876)">
            <path
                d="M7.50008 18.3327H12.5001C16.6667 18.3327 18.3334 16.666 18.3334 12.4993V7.49935C18.3334 3.33268 16.6667 1.66602 12.5001 1.66602H7.50008C3.33341 1.66602 1.66675 3.33268 1.66675 7.49935V12.4993C1.66675 16.666 3.33341 18.3327 7.50008 18.3327Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.653 12.908L11.863 6.876H13.124L10.329 14H8.951L6.156 6.876H7.43L9.653 12.908Z"
                fill="currentColor"
            />
        </g>
        <defs>
            <clipPath id="clip0_425_5876">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export const IcAdd = (
    <svg
        className="group-hover:hidden"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_464_6367)">
            <path
                d="M6.66675 9.99935H13.3334M10.0001 13.3327V6.66602M10.0001 18.3327C14.5834 18.3327 18.3334 14.5827 18.3334 9.99935C18.3334 5.41602 14.5834 1.66602 10.0001 1.66602C5.41675 1.66602 1.66675 5.41602 1.66675 9.99935C1.66675 14.5827 5.41675 18.3327 10.0001 18.3327Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_464_6367">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export const IcAddFill = (
    <svg
        className="hidden group-hover:block"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M10.0001 1.66602C5.40841 1.66602 1.66675 5.40768 1.66675 9.99935C1.66675 14.591 5.40841 18.3327 10.0001 18.3327C14.5917 18.3327 18.3334 14.591 18.3334 9.99935C18.3334 5.40768 14.5917 1.66602 10.0001 1.66602ZM13.3334 10.6243H10.6251V13.3327C10.6251 13.6743 10.3417 13.9577 10.0001 13.9577C9.65841 13.9577 9.37508 13.6743 9.37508 13.3327V10.6243H6.66675C6.32508 10.6243 6.04175 10.341 6.04175 9.99935C6.04175 9.65768 6.32508 9.37435 6.66675 9.37435H9.37508V6.66602C9.37508 6.32435 9.65841 6.04102 10.0001 6.04102C10.3417 6.04102 10.6251 6.32435 10.6251 6.66602V9.37435H13.3334C13.6751 9.37435 13.9584 9.65768 13.9584 9.99935C13.9584 10.341 13.6751 10.6243 13.3334 10.6243Z"
            fill="currentColor"
        />
    </svg>
);

export const IcRvt = (
    <svg
        width="40"
        height="48"
        viewBox="0 0 201 241"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g opacity="0.3">
            <path
                opacity="0.3"
                d="M139.729 49.2595C134.261 49.2569 129.016 47.0839 125.148 43.2178C121.28 39.3516 119.105 34.1084 119.099 28.6395V0.0195313H26.9294C23.3947 0.019531 19.8947 0.715868 16.6292 2.06885C13.3636 3.42183 10.3967 5.4049 7.89771 7.90479C5.39875 10.4047 3.41683 13.3724 2.06507 16.6384C0.713299 19.9045 0.0182196 23.4049 0.0195331 26.9396V189.15C0.0301315 196.28 2.86995 203.114 7.91541 208.152C12.9609 213.19 19.7994 216.02 26.9294 216.02H137.53C141.064 216.02 144.564 215.323 147.83 213.97C151.095 212.617 154.062 210.634 156.561 208.134C159.06 205.634 161.042 202.667 162.394 199.401C163.746 196.135 164.441 192.634 164.439 189.099V49.2195L139.729 49.2595Z"
                fill="#078DEE"
            />
        </g>
        <path
            d="M164.44 49.2595H139.73C134.261 49.2569 129.016 47.0839 125.148 43.2178C121.28 39.3516 119.105 34.1084 119.1 28.6395V0.0195312L164.44 49.2595Z"
            fill="#078DEE"
        />
        <path
            d="M123.509 86.3604H34.5093C33.6526 86.3604 32.8311 86.02 32.2253 85.4143C31.6196 84.8086 31.2793 83.987 31.2793 83.1304C31.2793 82.2737 31.6196 81.4522 32.2253 80.8464C32.8311 80.2407 33.6526 79.9004 34.5093 79.9004H123.509C123.933 79.9004 124.352 79.984 124.744 80.1465C125.135 80.3089 125.491 80.547 125.79 80.847C126.089 81.1471 126.326 81.5032 126.487 81.895C126.648 82.2868 126.731 82.7067 126.729 83.1304C126.729 83.9853 126.39 84.8053 125.787 85.4108C125.183 86.0162 124.364 86.3577 123.509 86.3604Z"
            fill="#078DEE"
        />
        <path
            d="M183.959 170.619H58.9193C50.0496 170.619 42.8594 177.81 42.8594 186.679V223.959C42.8594 232.829 50.0496 240.019 58.9193 240.019H183.959C192.829 240.019 200.019 232.829 200.019 223.959V186.679C200.019 177.81 192.829 170.619 183.959 170.619Z"
            fill="#078DEE"
        />
        <path
            d="M93.4399 221.075L86.9299 209.715H85.27V221.075H78V191.005H90.4399C92.508 190.948 94.5614 191.369 96.4399 192.235C97.9822 192.969 99.2704 194.145 100.14 195.615C100.977 197.118 101.402 198.815 101.37 200.535C101.426 202.543 100.791 204.51 99.5698 206.105C98.2235 207.72 96.3693 208.831 94.3101 209.255L101.46 221.075H93.4399ZM85.27 204.745H89.78C90.9321 204.832 92.0735 204.474 92.97 203.745C93.3251 203.37 93.5975 202.924 93.7698 202.436C93.942 201.949 94.0102 201.431 93.97 200.915C94.0054 200.405 93.9348 199.892 93.7627 199.411C93.5906 198.929 93.3207 198.488 92.97 198.115C92.5338 197.747 92.029 197.47 91.4849 197.298C90.9408 197.126 90.3683 197.064 89.8 197.115H85.29L85.27 204.745Z"
            fill="#F9FAFB"
        />
        <path
            d="M115.23 191L122.6 213.63L129.96 191H137.74L127.28 221.07H117.88L107.46 191H115.23Z"
            fill="#F9FAFB"
        />
        <path
            d="M167.3 191V196.78H159.14V221.07H151.82V196.78H143.74V191H167.3Z"
            fill="#F9FAFB"
        />
        <path
            d="M70.7292 213.389V220.669H63.1992V213.389H70.7292Z"
            fill="#F9FAFB"
        />
        <path
            d="M100.54 58.1891V147.189C100.541 147.614 100.459 148.034 100.297 148.427C100.135 148.819 99.897 149.176 99.5968 149.476C99.2966 149.776 98.94 150.014 98.5475 150.176C98.155 150.338 97.7344 150.42 97.3098 150.419C96.8861 150.419 96.4667 150.335 96.0753 150.173C95.684 150.011 95.3286 149.772 95.0294 149.472C94.7303 149.172 94.4933 148.816 94.332 148.424C94.1708 148.032 94.0885 147.613 94.0899 147.189V58.1891C94.0899 57.3342 94.4288 56.514 95.0324 55.9086C95.6359 55.3031 96.4549 54.9616 97.3098 54.959C98.1665 54.959 98.9881 55.2993 99.5939 55.905C100.2 56.5108 100.54 57.3324 100.54 58.1891Z"
            fill="#078DEE"
        />
        <path
            d="M107.83 72.3789H87.2197V92.9889H107.83V72.3789Z"
            fill="#078DEE"
        />
    </svg>
);

export const IcTxt = (
    <svg
        width="40"
        height="48"
        viewBox="0 0 201 240"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g opacity="0.3">
            <path
                opacity="0.3"
                d="M139.689 49.25C134.221 49.2447 128.979 47.0709 125.111 43.2053C121.244 39.3398 119.067 34.0979 119.059 28.6299V0H26.9294C19.7925 0 12.9477 2.83523 7.90112 7.88184C2.85452 12.9284 0.0195312 19.7729 0.0195312 26.9099V189.13C0.0195312 196.267 2.85452 203.112 7.90112 208.158C12.9477 213.205 19.7925 216.04 26.9294 216.04H137.53C144.667 216.04 151.511 213.205 156.558 208.158C161.604 203.112 164.439 196.267 164.439 189.13V49.25H139.689Z"
                fill="#078DEE"
            />
        </g>
        <path
            d="M164.44 49.25H139.69C134.222 49.2447 128.979 47.0709 125.111 43.2053C121.244 39.3398 119.068 34.0979 119.06 28.6299V0L164.44 49.25Z"
            fill="#078DEE"
        />
        <path
            d="M130.619 83.7302H27.3689C26.3778 83.7276 25.4281 83.3327 24.7273 82.6318C24.0265 81.931 23.6315 80.9813 23.6289 79.9902C23.6289 78.9974 24.0225 78.045 24.7236 77.342C25.4247 76.6391 26.3761 76.2429 27.3689 76.2402H130.619C131.612 76.2429 132.563 76.6391 133.264 77.342C133.965 78.045 134.359 78.9974 134.359 79.9902C134.356 80.9813 133.961 81.931 133.26 82.6318C132.56 83.3327 131.61 83.7276 130.619 83.7302Z"
            fill="#078DEE"
        />
        <path
            d="M130.619 106.099H27.3689C26.3778 106.097 25.4281 105.702 24.7273 105.001C24.0265 104.3 23.6315 103.35 23.6289 102.359C23.6289 101.367 24.0225 100.414 24.7236 99.7112C25.4247 99.0082 26.3761 98.612 27.3689 98.6094H130.619C131.612 98.612 132.563 99.0082 133.264 99.7112C133.965 100.414 134.359 101.367 134.359 102.359C134.356 103.35 133.961 104.3 133.26 105.001C132.56 105.702 131.61 106.097 130.619 106.099Z"
            fill="#078DEE"
        />
        <path
            d="M130.619 128.47H27.3689C26.3761 128.468 25.4247 128.072 24.7236 127.369C24.0225 126.666 23.6289 125.713 23.6289 124.72C23.6315 123.729 24.0265 122.78 24.7273 122.079C25.4281 121.378 26.3778 120.983 27.3689 120.98H130.619C131.61 120.983 132.56 121.378 133.26 122.079C133.961 122.78 134.356 123.729 134.359 124.72C134.359 125.713 133.965 126.666 133.264 127.369C132.563 128.072 131.612 128.468 130.619 128.47Z"
            fill="#078DEE"
        />
        <path
            d="M130.619 150.83H27.3689C26.377 150.83 25.4258 150.436 24.7244 149.734C24.023 149.033 23.6289 148.082 23.6289 147.09C23.6289 146.097 24.0225 145.145 24.7236 144.442C25.4247 143.739 26.3761 143.342 27.3689 143.34H130.619C131.612 143.342 132.563 143.739 133.264 144.442C133.965 145.145 134.359 146.097 134.359 147.09C134.359 148.082 133.965 149.033 133.263 149.734C132.562 150.436 131.611 150.83 130.619 150.83Z"
            fill="#078DEE"
        />
        <path
            d="M183.959 170.609H58.9194C50.0497 170.609 42.8594 177.8 42.8594 186.669V223.949C42.8594 232.819 50.0497 240.009 58.9194 240.009H183.959C192.829 240.009 200.02 232.819 200.02 223.949V186.669C200.02 177.8 192.829 170.609 183.959 170.609Z"
            fill="#078DEE"
        />
        <path
            d="M85.5789 213.379V220.649H78.0488V213.379H85.5789Z"
            fill="#F9FAFB"
        />
        <path
            d="M110.969 190.58V196.37H102.799V220.65H95.4895V196.37H87.3994V190.58H110.969Z"
            fill="#F9FAFB"
        />
        <path
            d="M132.28 220.65L125.939 211.29L120.49 220.65H112.159L121.77 205.17L111.859 190.58H120.49L126.659 199.64L131.979 190.58H140.27L130.789 205.72L140.909 220.65H132.28Z"
            fill="#F9FAFB"
        />
        <path
            d="M165.32 190.58V196.37H157.16V220.65H149.84V196.37H141.76V190.58H165.32Z"
            fill="#F9FAFB"
        />
    </svg>
);

export const IcE57 = (
    <svg
        width="40"
        height="48"
        viewBox="0 0 201 240"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g opacity="0.3">
            <path
                opacity="0.3"
                d="M139.689 49.25C134.221 49.2447 128.979 47.0709 125.111 43.2053C121.244 39.3398 119.067 34.0979 119.059 28.6299V0H26.9294C19.7925 0 12.9477 2.83523 7.90112 7.88184C2.85452 12.9284 0.0195312 19.7729 0.0195312 26.9099V189.13C0.0195312 196.267 2.85452 203.112 7.90112 208.158C12.9477 213.205 19.7925 216.04 26.9294 216.04H137.53C144.667 216.04 151.511 213.205 156.558 208.158C161.604 203.112 164.439 196.267 164.439 189.13V49.25H139.689Z"
                fill="#078DEE"
            />
        </g>
        <path
            d="M164.44 49.25H139.69C134.222 49.2447 128.979 47.0709 125.111 43.2053C121.244 39.3398 119.068 34.0979 119.06 28.6299V0L164.44 49.25Z"
            fill="#078DEE"
        />
        <path
            d="M130.619 83.7302H27.3689C26.3778 83.7276 25.4281 83.3327 24.7273 82.6318C24.0265 81.931 23.6315 80.9813 23.6289 79.9902C23.6289 78.9974 24.0225 78.045 24.7236 77.342C25.4247 76.6391 26.3761 76.2429 27.3689 76.2402H130.619C131.612 76.2429 132.563 76.6391 133.264 77.342C133.965 78.045 134.359 78.9974 134.359 79.9902C134.356 80.9813 133.961 81.931 133.26 82.6318C132.56 83.3327 131.61 83.7276 130.619 83.7302Z"
            fill="#078DEE"
        />
        <path
            d="M130.619 106.099H27.3689C26.3778 106.097 25.4281 105.702 24.7273 105.001C24.0265 104.3 23.6315 103.35 23.6289 102.359C23.6289 101.367 24.0225 100.414 24.7236 99.7112C25.4247 99.0082 26.3761 98.612 27.3689 98.6094H130.619C131.612 98.612 132.563 99.0082 133.264 99.7112C133.965 100.414 134.359 101.367 134.359 102.359C134.356 103.35 133.961 104.3 133.26 105.001C132.56 105.702 131.61 106.097 130.619 106.099Z"
            fill="#078DEE"
        />
        <path
            d="M130.619 128.47H27.3689C26.3761 128.468 25.4247 128.072 24.7236 127.369C24.0225 126.666 23.6289 125.713 23.6289 124.72C23.6315 123.729 24.0265 122.78 24.7273 122.079C25.4281 121.378 26.3778 120.983 27.3689 120.98H130.619C131.61 120.983 132.56 121.378 133.26 122.079C133.961 122.78 134.356 123.729 134.359 124.72C134.359 125.713 133.965 126.666 133.264 127.369C132.563 128.072 131.612 128.468 130.619 128.47Z"
            fill="#078DEE"
        />
        <path
            d="M130.619 150.83H27.3689C26.377 150.83 25.4258 150.436 24.7244 149.734C24.023 149.033 23.6289 148.082 23.6289 147.09C23.6289 146.097 24.0225 145.145 24.7236 144.442C25.4247 143.739 26.3761 143.342 27.3689 143.34H130.619C131.612 143.342 132.563 143.739 133.264 144.442C133.965 145.145 134.359 146.097 134.359 147.09C134.359 148.082 133.965 149.033 133.263 149.734C132.562 150.436 131.611 150.83 130.619 150.83Z"
            fill="#078DEE"
        />
        <path
            d="M183.959 170.609H58.9194C50.0497 170.609 42.8594 177.8 42.8594 186.669V223.949C42.8594 232.819 50.0497 240.009 58.9194 240.009H183.959C192.829 240.009 200.02 232.819 200.02 223.949V186.669C200.02 177.8 192.829 170.609 183.959 170.609Z"
            fill="#078DEE"
        />
        <path d="M79 213V221H71V213H79Z" fill="#F9FAFB" />
        <path
            d="M88.6548 221V190.455H109.237V195.779H95.1129V203.058H108.178V208.382H95.1129V215.675H109.297V221H88.6548ZM128.709 221.418C126.601 221.418 124.722 221.03 123.071 220.254C121.43 219.479 120.128 218.41 119.163 217.048C118.199 215.685 117.697 214.124 117.657 212.364H123.921C123.991 213.548 124.488 214.507 125.413 215.243C126.337 215.979 127.436 216.347 128.709 216.347C129.723 216.347 130.618 216.123 131.394 215.675C132.179 215.218 132.791 214.587 133.228 213.781C133.676 212.966 133.899 212.031 133.899 210.977C133.899 209.903 133.671 208.959 133.213 208.143C132.766 207.328 132.144 206.692 131.349 206.234C130.553 205.777 129.644 205.543 128.619 205.533C127.725 205.533 126.854 205.717 126.009 206.085C125.174 206.453 124.523 206.955 124.055 207.592L118.313 206.562L119.76 190.455H138.433V195.734H125.085L124.294 203.386H124.473C125.01 202.63 125.82 202.004 126.904 201.506C127.988 201.009 129.201 200.761 130.543 200.761C132.383 200.761 134.024 201.193 135.465 202.058C136.907 202.923 138.046 204.112 138.881 205.623C139.716 207.124 140.129 208.854 140.119 210.813C140.129 212.871 139.651 214.701 138.687 216.302C137.732 217.893 136.395 219.146 134.675 220.06C132.965 220.965 130.976 221.418 128.709 221.418ZM149.191 221L161.854 195.943V195.734H147.103V190.455H168.536V195.809L155.858 221H149.191Z"
            fill="white"
        />
    </svg>
);

export const IcArrowDown = (
    <svg
        width="9"
        height="14"
        viewBox="0 0 9 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.625 0.777344C4.74286 0.777344 4.85589 0.824162 4.93923 0.9075C5.02256 0.990838 5.06938 1.10387 5.06938 1.22173V11.7029L7.86543 8.90598C7.94888 8.82254 8.06205 8.77566 8.18006 8.77566C8.29806 8.77566 8.41124 8.82254 8.49468 8.90598C8.57812 8.98942 8.625 9.1026 8.625 9.2206C8.625 9.33861 8.57812 9.45178 8.49468 9.53523L4.93962 13.0903C4.89834 13.1317 4.8493 13.1645 4.79532 13.1869C4.74133 13.2093 4.68345 13.2208 4.625 13.2208C4.56655 13.2208 4.50867 13.2093 4.45468 13.1869C4.4007 13.1645 4.35166 13.1317 4.31038 13.0903L0.755321 9.53523C0.671878 9.45178 0.625 9.33861 0.625 9.2206C0.625 9.1026 0.671878 8.98942 0.755321 8.90598C0.838764 8.82254 0.951937 8.77566 1.06994 8.77566C1.18795 8.77566 1.30112 8.82254 1.38457 8.90598L4.18062 11.7029V1.22173C4.18062 1.10387 4.22744 0.990838 4.31078 0.9075C4.39411 0.824162 4.50714 0.777344 4.625 0.777344Z"
            fill="#078DEE"
        />
    </svg>
);

export const IcRemove = (
    <svg
        className="group-hover:hidden"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_464_6359)">
            <path
                d="M6.66675 9.99935H13.3334M10.0001 18.3327C14.5834 18.3327 18.3334 14.5827 18.3334 9.99935C18.3334 5.41602 14.5834 1.66602 10.0001 1.66602C5.41675 1.66602 1.66675 5.41602 1.66675 9.99935C1.66675 14.5827 5.41675 18.3327 10.0001 18.3327Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_464_6359">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export const IcRemoveFill = (
    <svg
        className="hidden group-hover:block"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M10.0001 1.66602C5.40841 1.66602 1.66675 5.40768 1.66675 9.99935C1.66675 14.591 5.40841 18.3327 10.0001 18.3327C14.5917 18.3327 18.3334 14.591 18.3334 9.99935C18.3334 5.40768 14.5917 1.66602 10.0001 1.66602ZM13.2667 10.6243H6.60008C6.25841 10.6243 5.97508 10.341 5.97508 9.99935C5.97508 9.65768 6.25841 9.37435 6.60008 9.37435H13.2667C13.4325 9.37435 13.5915 9.4402 13.7087 9.55741C13.8259 9.67462 13.8917 9.83359 13.8917 9.99935C13.8917 10.1651 13.8259 10.3241 13.7087 10.4413C13.5915 10.5585 13.4325 10.6243 13.2667 10.6243Z"
            fill="currentColor"
        />
    </svg>
);

export const IcPlusSquare = (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M5.3335 8H10.6668"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8 10.6673V5.33398"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M6.00016 14.6673H10.0002C13.3335 14.6673 14.6668 13.334 14.6668 10.0007V6.00065C14.6668 2.66732 13.3335 1.33398 10.0002 1.33398H6.00016C2.66683 1.33398 1.3335 2.66732 1.3335 6.00065V10.0007C1.3335 13.334 2.66683 14.6673 6.00016 14.6673Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const IcMessage = (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8.5 10.4997H15.5M7 18.4297H11L15.45 21.3897C15.6002 21.4898 15.7747 21.5473 15.955 21.556C16.1353 21.5646 16.3145 21.5242 16.4736 21.439C16.6328 21.3538 16.7658 21.227 16.8585 21.0722C16.9512 20.9173 17.0001 20.7402 17 20.5597V18.4297C20 18.4297 22 16.4297 22 13.4297V7.42969C22 4.42969 20 2.42969 17 2.42969H7C4 2.42969 2 4.42969 2 7.42969V13.4297C2 16.4297 4 18.4297 7 18.4297Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const IcMessageFill = (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M17 2H7C4.24 2 2 4.23 2 6.98V13.96C2 16.71 4.24 18.94 7 18.94H8.5C8.77 18.94 9.13 19.12 9.3 19.34L10.8 21.33C11.46 22.21 12.54 22.21 13.2 21.33L14.7 19.34C14.89 19.09 15.19 18.94 15.5 18.94H17C19.76 18.94 22 16.71 22 13.96V6.98C22 4.23 19.76 2 17 2ZM8 12C7.44 12 7 11.55 7 11C7 10.45 7.45 10 8 10C8.55 10 9 10.45 9 11C9 11.55 8.56 12 8 12ZM12 12C11.44 12 11 11.55 11 11C11 10.45 11.45 10 12 10C12.55 10 13 10.45 13 11C13 11.55 12.56 12 12 12ZM16 12C15.44 12 15 11.55 15 11C15 10.45 15.45 10 16 10C16.55 10 17 10.45 17 11C17 11.55 16.56 12 16 12Z"
            fill="currentColor"
        />
    </svg>
);

export const IcSend = (
    <svg
        width="24"
        height="24"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M14.1097 17.6502L17.6897 14.0602M11.3997 10.3202L19.8897 7.49015C23.6997 6.22015 25.7697 8.30015 24.5097 12.1102L21.6797 20.6002C19.7797 26.3102 16.6597 26.3102 14.7597 20.6002L13.9197 18.0802L11.3997 17.2402C5.68969 15.3402 5.68969 12.2302 11.3997 10.3202Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const IcUser = (
    <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M15.41 2.99975C17.35 2.99975 18.91 4.56975 18.91 6.49975C18.91 8.38975 17.41 9.92975 15.54 9.99975C15.4536 9.98974 15.3664 9.98974 15.28 9.99975M17.34 18.9997C18.06 18.8497 18.74 18.5597 19.3 18.1297C20.86 16.9597 20.86 15.0297 19.3 13.8597C18.75 13.4397 18.08 13.1597 17.37 12.9997M8.15997 9.86975C8.05997 9.85975 7.93997 9.85975 7.82997 9.86975C6.68217 9.83078 5.59461 9.34658 4.7976 8.51971C4.00059 7.69283 3.55671 6.5882 3.55997 5.43975C3.55997 2.98975 5.53997 0.999746 7.99997 0.999746C9.17621 0.978529 10.3127 1.42544 11.1594 2.24216C12.0061 3.05888 12.4938 4.17851 12.515 5.35475C12.5362 6.53098 12.0893 7.66747 11.2726 8.5142C10.4558 9.36092 9.33621 9.84853 8.15997 9.86975ZM3.15997 13.5597C0.739971 15.1797 0.739971 17.8197 3.15997 19.4297C5.90997 21.2697 10.42 21.2697 13.17 19.4297C15.59 17.8097 15.59 15.1697 13.17 13.5597C10.43 11.7297 5.91997 11.7297 3.15997 13.5597Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const IcAvatar = (
    <svg
        width="128"
        height="128"
        viewBox="0 0 128 128"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            cx="64"
            cy="64"
            r="63.5"
            transform="matrix(-1 0 0 1 128 0)"
            stroke="currentColor"
            strokeLinecap="round"
            strokeDasharray="5 5"
        />
        <path
            d="M73.68 68.96L70.55 61.65C69.49 59.17 67.54 59.07 66.23 61.43L64.34 64.84C63.38 66.57 61.59 66.72 60.35 65.17L60.13 64.89C58.84 63.27 57.02 63.47 56.09 65.32L54.37 68.77C53.16 71.17 54.91 74 57.59 74H70.35C72.95 74 74.7 71.35 73.68 68.96ZM58.97 60C59.7657 60 60.5288 59.6839 61.0914 59.1213C61.654 58.5587 61.97 57.7956 61.97 57C61.97 56.2044 61.654 55.4413 61.0914 54.8787C60.5288 54.3161 59.7657 54 58.97 54C58.1744 54 57.4113 54.3161 56.8487 54.8787C56.2861 55.4413 55.97 56.2044 55.97 57C55.97 57.7956 56.2861 58.5587 56.8487 59.1213C57.4113 59.6839 58.1744 60 58.97 60Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const IcSearch = (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.5379 15.5582C12.1367 16.556 10.4226 17.1429 8.57143 17.1429C3.83756 17.1429 0 13.3053 0 8.57143C0 3.83756 3.83756 0 8.57143 0C13.3053 0 17.1429 3.83756 17.1429 8.57143C17.1429 10.4226 16.556 12.1367 15.5582 13.5379L19.5816 17.5613C20.1395 18.1192 20.1395 19.0237 19.5816 19.5816C19.0237 20.1395 18.1192 20.1395 17.5613 19.5816L13.5379 15.5582ZM14.2857 8.57143C14.2857 11.7273 11.7273 14.2857 8.57143 14.2857C5.41551 14.2857 2.85714 11.7273 2.85714 8.57143C2.85714 5.41551 5.41551 2.85714 8.57143 2.85714C11.7273 2.85714 14.2857 5.41551 14.2857 8.57143Z"
            fill="currentColor"
        />
    </svg>
);

export const IcSelectArrow = (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12.7074 5.23648C12.6146 5.14355 12.5043 5.06984 12.3829 5.01954C12.2615 4.96925 12.1315 4.94336 12.0001 4.94336C11.8687 4.94336 11.7386 4.96925 11.6173 5.01954C11.4959 5.06984 11.3856 5.14355 11.2928 5.23648L8.23542 8.29315C8.17291 8.35564 8.08814 8.39074 7.99975 8.39074C7.91136 8.39074 7.82659 8.35564 7.76408 8.29315L4.70742 5.23648C4.51991 5.04888 4.26556 4.94346 4.00032 4.94339C3.73508 4.94333 3.48068 5.04864 3.29308 5.23615C3.10549 5.42365 3.00006 5.678 3 5.94324C2.99994 6.20848 3.10524 6.46288 3.29275 6.65048L6.35008 9.70781C6.56676 9.92451 6.82399 10.0964 7.1071 10.2137C7.39021 10.331 7.69365 10.3913 8.00008 10.3913C8.30652 10.3913 8.60996 10.331 8.89307 10.2137C9.17618 10.0964 9.43341 9.92451 9.65008 9.70781L12.7074 6.65048C12.8949 6.46295 13.0002 6.20864 13.0002 5.94348C13.0002 5.67832 12.8949 5.42401 12.7074 5.23648Z"
            fill="currentColor"
        />
    </svg>
);

export const IcModalClose = (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_887_6096)">
            <path
                d="M9.17113 14.8284L14.828 9.17157M14.828 14.8284L9.17113 9.17157M19.0706 19.0711C22.9597 15.182 22.9597 8.81802 19.0706 4.92893C15.1815 1.03985 8.81758 1.03985 4.92849 4.92893C1.0394 8.81802 1.0394 15.182 4.92849 19.0711C8.81758 22.9602 15.1815 22.9602 19.0706 19.0711Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_887_6096">
                <rect width="24" height="24" fill="currentColor" />
            </clipPath>
        </defs>
    </svg>
);

export const IcModalBack = (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M9.57 5.92969L3.5 11.9997L9.57 18.0697"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M20.4999 12H3.66992"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const IcModalConfirm = (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M7.75 11.9999L10.58 14.8299L16.25 9.16992"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const IcModalDelete = (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M21 5.98047C17.67 5.65047 14.32 5.48047 10.98 5.48047C9 5.48047 7.02 5.58047 5.04 5.78047L3 5.98047"
            stroke="#F9FAFB"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97"
            stroke="#F9FAFB"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M18.8499 9.13965L18.1999 19.2096C18.0899 20.7796 17.9999 21.9996 15.2099 21.9996H8.7899C5.9999 21.9996 5.9099 20.7796 5.7999 19.2096L5.1499 9.13965"
            stroke="#F9FAFB"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M10.3301 16.5H13.6601"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M9.5 12.5H14.5"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const IcHeaderHome = (
    <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M9 13.5V11.25"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M7.5525 2.11586L2.355 6.27836C1.77 6.74336 1.395 7.72586 1.5225 8.46086L2.52 14.4309C2.7 15.4959 3.72 16.3584 4.8 16.3584H13.2C14.2725 16.3584 15.3 15.4884 15.48 14.4309L16.4775 8.46086C16.5975 7.72586 16.2225 6.74336 15.645 6.27836L10.4475 2.12336C9.645 1.47836 8.3475 1.47836 7.5525 2.11586Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const IcTickCircle = (
    <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8.49967 14.6673C12.1663 14.6673 15.1663 11.6673 15.1663 8.00065C15.1663 4.33398 12.1663 1.33398 8.49967 1.33398C4.83301 1.33398 1.83301 4.33398 1.83301 8.00065C1.83301 11.6673 4.83301 14.6673 8.49967 14.6673Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M5.66699 7.99995L7.55366 9.88661L11.3337 6.11328"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const IcThreeDots = (
    <svg
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12 4C12.5477 4 13 4.45228 13 5C13 5.54772 12.5477 6 12 6C11.4523 6 11 5.54772 11 5C11 4.45228 11.4523 4 12 4Z"
            stroke="currentColor"
            strokeWidth="2"
        />
        <path
            d="M12 18C12.5477 18 13 18.4523 13 19C13 19.5477 12.5477 20 12 20C11.4523 20 11 19.5477 11 19C11 18.4523 11.4523 18 12 18Z"
            stroke="currentColor"
            strokeWidth="2"
        />
        <path
            d="M12 11C12.5477 11 13 11.4523 13 12C13 12.5477 12.5477 13 12 13C11.4523 13 11 12.5477 11 12C11 11.4523 11.4523 11 12 11Z"
            stroke="currentColor"
            strokeWidth="2"
        />
    </svg>
);

export const IcEdit = (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M5.53999 19.5196C4.92999 19.5196 4.35999 19.3096 3.94999 18.9196C3.42999 18.4296 3.17999 17.6896 3.26999 16.8896L3.63999 13.6496C3.70999 13.0396 4.07999 12.2296 4.50999 11.7896L12.72 3.09956C14.77 0.929561 16.91 0.869561 19.08 2.91956C21.25 4.96956 21.31 7.10956 19.26 9.27956L11.05 17.9696C10.63 18.4196 9.84999 18.8396 9.23999 18.9396L6.01999 19.4896C5.84999 19.4996 5.69999 19.5196 5.53999 19.5196ZM15.93 2.90956C15.16 2.90956 14.49 3.38956 13.81 4.10956L5.59999 12.8096C5.39999 13.0196 5.16999 13.5196 5.12999 13.8096L4.75999 17.0496C4.71999 17.3796 4.79999 17.6496 4.97999 17.8196C5.15999 17.9896 5.42999 18.0496 5.75999 17.9996L8.97999 17.4496C9.26999 17.3996 9.74999 17.1396 9.94999 16.9296L18.16 8.23956C19.4 6.91956 19.85 5.69956 18.04 3.99956C17.24 3.22956 16.55 2.90956 15.93 2.90956Z"
            fill="currentColor"
        />
        <path
            d="M17.34 10.9489H17.27C15.7545 10.8002 14.3315 10.1516 13.2253 9.10509C12.1191 8.05861 11.3925 6.67381 11.16 5.16887C11.1 4.75887 11.38 4.37887 11.79 4.30887C12.2 4.24887 12.58 4.52887 12.65 4.93887C12.8331 6.11552 13.402 7.19798 14.2672 8.01615C15.1324 8.83431 16.245 9.3418 17.43 9.45887C17.84 9.49887 18.14 9.86887 18.1 10.2789C18.05 10.6589 17.72 10.9489 17.34 10.9489ZM21 22.7499H3C2.59 22.7499 2.25 22.4099 2.25 21.9999C2.25 21.5899 2.59 21.2499 3 21.2499H21C21.41 21.2499 21.75 21.5899 21.75 21.9999C21.75 22.4099 21.41 22.7499 21 22.7499Z"
            fill="currentColor"
        />
    </svg>
);

export const IcNote = (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M6.66667 1.66602V4.16602M13.3333 1.66602V4.16602M5.83333 10.8327H12.5M5.83333 14.166H10M13.3333 2.91602C16.1083 3.06602 17.5 4.12435 17.5 8.04102V13.191C17.5 16.6243 16.6667 18.341 12.5 18.341H7.5C3.33333 18.341 2.5 16.6243 2.5 13.191V8.04102C2.5 4.12435 3.89167 3.07435 6.66667 2.91602H13.3333Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const IcDetailDescription = (
    <svg
        width="18"
        height="18"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M6.66667 1.66797V4.16797M13.3333 1.66797V4.16797M5.83333 10.8346H12.5M5.83333 14.168H10M13.3333 2.91797C16.1083 3.06797 17.5 4.1263 17.5 8.04297V13.193C17.5 16.6263 16.6667 18.343 12.5 18.343H7.5C3.33333 18.343 2.5 16.6263 2.5 13.193V8.04297C2.5 4.1263 3.89167 3.0763 6.66667 2.91797H13.3333Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const IcDetailCategory = (
    <svg
        width="18"
        height="18"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_1011_6679)">
            <path
                d="M14.1667 8.33268H15.8334C17.5 8.33268 18.3334 7.49935 18.3334 5.83268V4.16602C18.3334 2.49935 17.5 1.66602 15.8334 1.66602H14.1667C12.5 1.66602 11.6667 2.49935 11.6667 4.16602V5.83268C11.6667 7.49935 12.5 8.33268 14.1667 8.33268ZM4.16669 18.3327H5.83335C7.50002 18.3327 8.33335 17.4993 8.33335 15.8327V14.166C8.33335 12.4993 7.50002 11.666 5.83335 11.666H4.16669C2.50002 11.666 1.66669 12.4993 1.66669 14.166V15.8327C1.66669 17.4993 2.50002 18.3327 4.16669 18.3327ZM5.00002 8.33268C5.88408 8.33268 6.73192 7.98149 7.35704 7.35637C7.98216 6.73125 8.33335 5.8834 8.33335 4.99935C8.33335 4.11529 7.98216 3.26745 7.35704 2.64233C6.73192 2.01721 5.88408 1.66602 5.00002 1.66602C4.11597 1.66602 3.26812 2.01721 2.643 2.64233C2.01788 3.26745 1.66669 4.11529 1.66669 4.99935C1.66669 5.8834 2.01788 6.73125 2.643 7.35637C3.26812 7.98149 4.11597 8.33268 5.00002 8.33268ZM15 18.3327C15.8841 18.3327 16.7319 17.9815 17.357 17.3564C17.9822 16.7312 18.3334 15.8834 18.3334 14.9993C18.3334 14.1153 17.9822 13.2674 17.357 12.6423C16.7319 12.0172 15.8841 11.666 15 11.666C14.116 11.666 13.2681 12.0172 12.643 12.6423C12.0179 13.2674 11.6667 14.1153 11.6667 14.9993C11.6667 15.8834 12.0179 16.7312 12.643 17.3564C13.2681 17.9815 14.116 18.3327 15 18.3327Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_1011_6679">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export const IcDetailBuildingType = (
    <svg
        width="18"
        height="18"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_1011_6684)">
            <path
                d="M10.4167 18.333H3.4C2.43333 18.333 1.64166 17.558 1.64166 16.608V4.24131C1.64166 2.05797 3.26666 1.06631 5.25833 2.04131L8.95833 3.85797C9.75833 4.24964 10.4167 5.29131 10.4167 6.17464V18.333ZM10.4167 18.333H15.675C17.475 18.333 18.3083 17.4996 18.3083 15.6996V12.5496C18.3083 12.4913 18.3083 12.4413 18.3 12.3913C18.25 10.7913 17.35 10.2246 16.25 9.98297L14.5583 9.60797L10.8083 8.76631L10.4167 8.68297V18.333ZM4.58333 7.49964H7.475M4.58333 10.833H7.475"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.5583 9.60924V12.2926M14.5583 9.60924L10.8083 8.76758V12.2926C10.8083 13.3259 11.65 14.1676 12.6833 14.1676C13.7167 14.1676 14.5583 13.3259 14.5583 12.2926M14.5583 9.60924L16.25 9.98424C17.35 10.2259 18.25 10.7926 18.3 12.3926C18.2747 12.871 18.0671 13.3215 17.7199 13.6516C17.3728 13.9817 16.9124 14.1663 16.4333 14.1676C15.4 14.1676 14.5583 13.3259 14.5583 12.2926"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_1011_6684">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export const IcDetailFloorArea = (
    <svg
        width="18"
        height="18"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_1011_6691)">
            <path
                d="M16.6666 5.79232V14.209C16.3292 14.14 15.9798 14.1558 15.6499 14.2549C15.32 14.354 15.0199 14.5334 14.7763 14.777C14.5327 15.0206 14.3534 15.3207 14.2542 15.6506C14.1551 15.9805 14.1393 16.3298 14.2083 16.6673H5.79165C5.86063 16.3298 5.84485 15.9805 5.74573 15.6506C5.6466 15.3207 5.46723 15.0206 5.22365 14.777C4.98008 14.5334 4.67991 14.354 4.35002 14.2549C4.02012 14.1558 3.6708 14.14 3.33331 14.209V5.79232C3.6708 5.8613 4.02012 5.84552 4.35002 5.7464C4.67991 5.64727 4.98008 5.4679 5.22365 5.22432C5.46723 4.98075 5.6466 4.68058 5.74573 4.35069C5.84485 4.0208 5.86063 3.67147 5.79165 3.33398H14.2083C14.1393 3.67147 14.1551 4.0208 14.2542 4.35069C14.3534 4.68058 14.5327 4.98075 14.7763 5.22432C15.0199 5.4679 15.32 5.64727 15.6499 5.7464C15.9798 5.84552 16.3292 5.8613 16.6666 5.79232Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.83335 3.74949C5.83335 4.30203 5.61386 4.83193 5.22316 5.22263C4.83246 5.61333 4.30256 5.83283 3.75002 5.83283C3.19749 5.83283 2.66758 5.61333 2.27688 5.22263C1.88618 4.83193 1.66669 4.30203 1.66669 3.74949C1.66617 3.23285 1.85764 2.73444 2.20393 2.35102C2.55022 1.96761 3.02662 1.72654 3.54065 1.67461C4.05468 1.62269 4.56967 1.76362 4.98564 2.07004C5.4016 2.37646 5.68888 2.82651 5.79169 3.33283C5.81669 3.46616 5.83335 3.60783 5.83335 3.74949ZM18.3334 3.74949C18.3334 4.30203 18.1139 4.83193 17.7232 5.22263C17.3325 5.61333 16.8026 5.83283 16.25 5.83283C15.6975 5.83283 15.1676 5.61333 14.7769 5.22263C14.3862 4.83193 14.1667 4.30203 14.1667 3.74949C14.1667 3.19696 14.3862 2.66706 14.7769 2.27636C15.1676 1.88565 15.6975 1.66616 16.25 1.66616C16.8026 1.66616 17.3325 1.88565 17.7232 2.27636C18.1139 2.66706 18.3334 3.19696 18.3334 3.74949ZM5.83335 16.2495C5.83335 16.802 5.61386 17.3319 5.22316 17.7226C4.83246 18.1133 4.30256 18.3328 3.75002 18.3328C3.19749 18.3328 2.66758 18.1133 2.27688 17.7226C1.88618 17.3319 1.66669 16.802 1.66669 16.2495C1.66669 15.697 1.88618 15.1671 2.27688 14.7764C2.66758 14.3857 3.19749 14.1662 3.75002 14.1662C4.30256 14.1662 4.83246 14.3857 5.22316 14.7764C5.61386 15.1671 5.83335 15.697 5.83335 16.2495ZM18.3334 16.2495C18.3339 16.7661 18.1424 17.2645 17.7961 17.648C17.4498 18.0314 16.9734 18.2725 16.4594 18.3244C15.9454 18.3763 15.4304 18.2354 15.0144 17.9289C14.5984 17.6225 14.3112 17.1725 14.2084 16.6662C14.1504 16.3809 14.1529 16.0867 14.2157 15.8025C14.2784 15.5182 14.4 15.2503 14.5727 15.016C14.7453 14.7816 14.9651 14.586 15.218 14.4419C15.4708 14.2977 15.7511 14.2081 16.0407 14.1788C16.3302 14.1496 16.6228 14.1813 16.8993 14.272C17.1759 14.3627 17.4304 14.5104 17.6464 14.7055C17.8624 14.9006 18.0351 15.1388 18.1535 15.4047C18.2718 15.6706 18.3331 15.9584 18.3334 16.2495Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_1011_6691">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export const IcDetailTeam = (
    <svg
        width="18"
        height="18"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M15.41 2.99975C17.35 2.99975 18.91 4.56975 18.91 6.49975C18.91 8.38975 17.41 9.92975 15.54 9.99975C15.4536 9.98974 15.3664 9.98974 15.28 9.99975M17.34 18.9997C18.06 18.8497 18.74 18.5597 19.3 18.1297C20.86 16.9597 20.86 15.0297 19.3 13.8597C18.75 13.4397 18.08 13.1597 17.37 12.9997M8.15997 9.86975C8.05997 9.85975 7.93997 9.85975 7.82997 9.86975C6.68217 9.83078 5.59461 9.34658 4.7976 8.51971C4.00059 7.69283 3.55671 6.5882 3.55997 5.43975C3.55997 2.98975 5.53997 0.999746 7.99997 0.999746C9.17621 0.978529 10.3127 1.42544 11.1594 2.24216C12.0061 3.05888 12.4938 4.17851 12.515 5.35475C12.5362 6.53098 12.0893 7.66747 11.2726 8.5142C10.4558 9.36092 9.33621 9.84853 8.15997 9.86975ZM3.15997 13.5597C0.739971 15.1797 0.739971 17.8197 3.15997 19.4297C5.90997 21.2697 10.42 21.2697 13.17 19.4297C15.59 17.8097 15.59 15.1697 13.17 13.5597C10.43 11.7297 5.91997 11.7297 3.15997 13.5597Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const IcDetailDate = (
    <svg
        width="18"
        height="18"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M6.66667 1.66602V4.16602M13.3333 1.66602V4.16602M2.91667 7.57435H17.0833M17.5 7.08268V14.166C17.5 16.666 16.25 18.3327 13.3333 18.3327H6.66667C3.75 18.3327 2.5 16.666 2.5 14.166V7.08268C2.5 4.58268 3.75 2.91602 6.66667 2.91602H13.3333C16.25 2.91602 17.5 4.58268 17.5 7.08268Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M9.99585 11.416H10.0042M6.91168 11.416H6.92002M6.91168 13.916H6.92002"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const IcNewTicket = (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.3333 6.19157V11.1916C18.3333 12.4416 17.9166 13.4832 17.1833 14.2166C16.4583 14.9416 15.4166 15.3582 14.1666 15.3582V17.1332C14.1666 17.7999 13.425 18.1999 12.875 17.8333L9.16663 15.3582H7.39996C7.46663 15.1082 7.49996 14.8499 7.49996 14.5832C7.49996 13.7332 7.17496 12.9499 6.64163 12.3583C6.04163 11.6749 5.14996 11.2499 4.16663 11.2499C3.23329 11.2499 2.3833 11.6333 1.77496 12.2583C1.69996 11.9249 1.66663 11.5666 1.66663 11.1916V6.19157C1.66663 3.69157 3.33329 2.0249 5.83329 2.0249H14.1666C16.6666 2.0249 18.3333 3.69157 18.3333 6.19157Z" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7.50004 14.5833C7.50004 14.85 7.46671 15.1083 7.40004 15.3583C7.32504 15.6917 7.19172 16.0167 7.01672 16.3C6.44172 17.2667 5.38337 17.9167 4.16671 17.9167C3.30837 17.9167 2.53337 17.5916 1.95004 17.0583C1.70004 16.8416 1.48336 16.5833 1.3167 16.3C1.00836 15.8 0.833374 15.2083 0.833374 14.5833C0.833374 13.6833 1.19171 12.8584 1.77504 12.2584C2.38338 11.6334 3.23337 11.25 4.16671 11.25C5.15004 11.25 6.04171 11.675 6.64171 12.3583C7.17504 12.95 7.50004 13.7333 7.50004 14.5833Z" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.40837 14.5669H2.92505" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4.16675 13.3501V15.8418" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7.08337 8.75H12.9167" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
)


export const IcIssueListArrow = (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M11.9465 5.45312H7.79316H4.05317C3.41317 5.45312 3.09317 6.22646 3.5465 6.67979L6.99983 10.1331C7.55317 10.6865 8.45317 10.6865 9.0065 10.1331L10.3198 8.81979L12.4598 6.67979C12.9065 6.22646 12.5865 5.45312 11.9465 5.45312Z"
            fill="currentColor"
        />
    </svg>
);

export const IcIssueBackArrow = (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M15.8203 6.08123L15.8203 17.9212C15.8203 18.8812 14.6603 19.3612 13.9803 18.6812L8.80031 13.5012C7.97031 12.6712 7.97031 11.3212 8.80031 10.4912L10.7703 8.52123L13.9803 5.31123C14.6603 4.64123 15.8203 5.12123 15.8203 6.08123Z"
            fill="currentColor"
        />
    </svg>
);

export const IcChangePassword = (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.18331 16.25H6.24998C5.73331 16.25 5.27498 16.2333 4.86665 16.175C2.67498 15.9333 2.08331 14.9 2.08331 12.0833V7.91667C2.08331 5.1 2.67498 4.06667 4.86665 3.825C5.27498 3.76667 5.73331 3.75 6.24998 3.75H9.13331" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12.5167 3.75H13.75C14.2667 3.75 14.725 3.76667 15.1333 3.825C17.325 4.06667 17.9167 5.1 17.9167 7.91667V12.0833C17.9167 14.9 17.325 15.9333 15.1333 16.175C14.725 16.2333 14.2667 16.25 13.75 16.25H12.5167" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12.5 1.66602V18.3327" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9.2454 10.0007H9.25289" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.91209 10.0007H5.91957" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
)

export const IcEmptySpace = (
    <svg width="100%" height="100%" viewBox="0 50 250 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M63 134H154C154.515 134 155.017 133.944 155.5 133.839C155.983 133.944 156.485 134 157 134H209C212.866 134 216 130.866 216 127C216 123.134 212.866 120 209 120H203C199.134 120 196 116.866 196 113C196 109.134 199.134 106 203 106H222C225.866 106 229 102.866 229 99C229 95.134 225.866 92 222 92H200C203.866 92 207 88.866 207 85C207 81.134 203.866 78 200 78H136C139.866 78 143 74.866 143 71C143 67.134 139.866 64 136 64H79C75.134 64 72 67.134 72 71C72 74.866 75.134 78 79 78H39C35.134 78 32 81.134 32 85C32 88.866 35.134 92 39 92H64C67.866 92 71 95.134 71 99C71 102.866 67.866 106 64 106H24C20.134 106 17 109.134 17 113C17 116.866 20.134 120 24 120H63C59.134 120 56 123.134 56 127C56 130.866 59.134 134 63 134ZM226 134C229.866 134 233 130.866 233 127C233 123.134 229.866 120 226 120C222.134 120 219 123.134 219 127C219 130.866 222.134 134 226 134Z" fill="#078DEE" />
        <path opacity="0.02" fillRule="evenodd" clipRule="evenodd" d="M113.119 112.307C113.04 112.86 113 113.425 113 114C113 120.627 118.373 126 125 126C131.627 126 137 120.627 137 114C137 113.425 136.96 112.86 136.881 112.307H166V139C166 140.657 164.657 142 163 142H87C85.3431 142 84 140.657 84 139V112.307H113.119Z" fill="#078DEE" />
        <path opacity="0.02" fillRule="evenodd" clipRule="evenodd" d="M138 112C138 119.18 132.18 125 125 125C117.82 125 112 119.18 112 112C112 111.767 112.006 111.536 112.018 111.307H84L93.5604 83.0389C93.9726 81.8202 95.1159 81 96.4023 81H153.598C154.884 81 156.027 81.8202 156.44 83.0389L166 111.307H137.982C137.994 111.536 138 111.767 138 112Z" fill="#078DEE" />
        <path fillRule="evenodd" clipRule="evenodd" d="M136.444 112.955C136.444 118.502 131.321 124 125 124C118.679 124 113.556 118.502 113.556 112.955C113.556 112.775 113.561 111.596 113.572 111.419H92L100.416 91.5755C100.779 90.6338 101.786 90 102.918 90H147.082C148.214 90 149.221 90.6338 149.584 91.5755L158 111.419H136.428C136.439 111.596 136.444 112.775 136.444 112.955Z" fill="#078DEE" />
        <path fillRule="evenodd" clipRule="evenodd" d="M85.25 111.512V138C85.25 138.966 86.0335 139.75 87 139.75H163C163.966 139.75 164.75 138.966 164.75 138V111.512L155.255 83.4393C155.015 82.7285 154.348 82.25 153.598 82.25H96.4023C95.6519 82.25 94.985 82.7285 94.7446 83.4393L85.25 111.512Z" stroke="#078DEE" strokeWidth="2.5" />
        <path d="M98 111C101.937 111 106.185 111 110.745 111C112.621 111 112.621 112.319 112.621 113C112.621 119.627 118.117 125 124.897 125C131.677 125 137.173 119.627 137.173 113C137.173 112.319 137.173 111 139.05 111H164M90.5737 111H93H90.5737Z" stroke="#078DEE" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M150.1 58.3027L139 70.7559M124.1 54V70.7559V54ZM98 58.3027L109.1 70.7559L98 58.3027Z" stroke="#078DEE" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
)

export const IcReset = (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M22 11V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H10M2.03 8.5H22M2.03 15.5H12M8.51 21.99V2.01M15.51 11.99V2.01"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M22 19C22 21.208 20.208 23 18 23C15.792 23 14.444 20.776 14.444 20.776M14.444 20.776H16.252M14.444 20.776V22.776M14 19C14 16.792 15.776 15 18 15C20.668 15 22 17.224 22 17.224M22 17.224V15.224M22 17.224H20.224"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const IcCopy = (
    <svg
        width="24"
        height="24"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M13.3334 10.7503V14.2503C13.3334 17.167 12.1667 18.3337 9.25002 18.3337H5.75002C2.83335 18.3337 1.66669 17.167 1.66669 14.2503V10.7503C1.66669 7.83366 2.83335 6.66699 5.75002 6.66699H9.25002C12.1667 6.66699 13.3334 7.83366 13.3334 10.7503Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M18.3334 5.75033V9.25033C18.3334 12.167 17.1667 13.3337 14.25 13.3337H13.3334V10.7503C13.3334 7.83366 12.1667 6.66699 9.25002 6.66699H6.66669V5.75033C6.66669 2.83366 7.83335 1.66699 10.75 1.66699H14.25C17.1667 1.66699 18.3334 2.83366 18.3334 5.75033Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const IcLoading = (
    <svg
        width="35"
        height="35"
        viewBox="0 0 35 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M19.1867 1.08643C21.3421 1.30793 23.4328 1.9518 25.3395 2.98129C27.2461 4.01079 28.9313 5.40574 30.2988 7.0865C31.6663 8.76726 32.6894 10.7009 33.3096 12.7771C33.9298 14.8532 34.1351 17.0312 33.9136 19.1867C33.6921 21.3421 33.0482 23.4328 32.0187 25.3395C30.9892 27.2461 29.5943 28.9313 27.9135 30.2988C26.2327 31.6663 24.2991 32.6894 22.2229 33.3096C20.1468 33.9298 17.9688 34.1351 15.8133 33.9136C13.6579 33.6921 11.5672 33.0482 9.66054 32.0187C7.75391 30.9892 6.06873 29.5943 4.70121 27.9135C3.33369 26.2327 2.31061 24.2991 1.69039 22.2229C1.07016 20.1468 0.864938 17.9688 1.08643 15.8133C1.30793 13.6579 1.9518 11.5672 2.98129 9.66054C4.01079 7.75391 5.40574 6.06873 7.0865 4.70121C8.76726 3.33369 10.7009 2.31061 12.7771 1.69039C14.8532 1.07016 17.0312 0.864938 19.1867 1.08643L19.1867 1.08643Z"
            stroke="url(#paint0_radial_684_6313)"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <radialGradient
                id="paint0_radial_684_6313"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(8.5 22) rotate(-66.8014) scale(22.8473)"
            >
                <stop stopColor="currentColor" />
                <stop offset="1" stopColor="currentColor" stopOpacity="0" />
            </radialGradient>
        </defs>
    </svg>
);

export const IcRadar = (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_294_5002)">
            <path
                d="M4.99999 3.33335C6.39166 2.29169 8.12499 1.66669 9.99999 1.66669C14.6 1.66669 18.3333 5.40002 18.3333 10C18.3333 14.6 14.6 18.3334 9.99999 18.3334C5.39999 18.3334 1.66666 14.6 1.66666 10C1.66666 8.49169 2.06666 7.07502 2.77499 5.85002L9.99999 10"
                stroke="#CCF4FE"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.69166 7.46667C5.23645 8.23311 4.99743 9.10857 4.99999 10C4.99999 12.7583 7.24166 15 9.99999 15C12.7583 15 15 12.7583 15 10C15 7.24167 12.7583 5 9.99999 5C9.24166 5 8.51666 5.16667 7.87499 5.475"
                stroke="#CCF4FE"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_294_5002">
                <rect width="20" height="20" fill="currentColor" />
            </clipPath>
        </defs>
    </svg>
);

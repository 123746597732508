import React, { useState, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { IcUpload } from '../../icons'
import Item from './Item'
import SubmitButton from './SubmitButton'

// const dropzoneProps = {
//   maxSize: 1000, // mb
//   accept: { //type of files
//     'image/*': [],
//     'text/html': ['.html', '.htm'],
//   },
//   maxFiles: 3, //number of files
//   maxTotalSize: 2000 // mb
// }

// TODO: maxFiles, maxTotalSize

function nameValidator(file) {
  const fileNameChars = (() => {
    // remove file type like .rvt at the end of name 
    const res = file.name.split(".")
    res.pop(res[res.length - 1])
    return res.join('.').split('')
  })()

  const acceptedChars = (() => {
    const upper = [...Array(26)].map((val, i) => String.fromCharCode(i + 65));
    const lower = upper.map(letter => letter.toLowerCase())
    const digit = [...Array(10)].map((val, i) => '' + i)
    return [...lower, ...upper, ...digit, '-', '_', ' ']
  })();

  const invalidChars = fileNameChars.map(i => !acceptedChars.includes(i) && i).filter(i => i !== false)

  if (invalidChars.length !== 0) {
    if (invalidChars.length === 1) {
      return {
        code: "invalid-name-char",
        message: `${invalidChars.join('')} is invalid character in file name(only letters, digits, -, _ and space)`
      };
    }
    return {
      code: "invalid-name-char",
      message: `${invalidChars.join('')} are invalid characters in file name(only letters, digits, -, _ and space)`
    };
  }

  return null
}

export default function Dropzone({ upload, accept, maxSize, maxFiles, maxTotalSize, lang }) {
  const [files, setFiles] = useState([])
  const [rejected, setRejected] = useState([])
  const [isSending, setIsSending] = useState(false)
  const [progress, setProgress] = useState({})

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (acceptedFiles?.length) {
      setFiles(previousFiles => [...previousFiles, ...acceptedFiles])
    }

    if (rejectedFiles?.length) {
      setRejected(previousFiles => [...previousFiles, ...rejectedFiles])
    }
  }, [])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: accept,
    maxSize: maxSize * 1024 * 1024,
    validator: nameValidator
  })

  const onSubmit = async (e) => {
    e.preventDefault()

    if (!files?.length) return
    setIsSending(prev => !prev)

    Promise.all(
      files.map(async file => {
        const body = new FormData()
        body.append('files', file)
        await upload?.uploadHandler(body, data => setProgress(prev => ({ ...prev, [file?.name]: Math.round((100 * data.loaded) / data.total) })))
      })
    ).then(() => {
      upload?.loading()
      upload?.downloadHandler()
    })
  }

  const removeFileFromPreview = name => {
    setFiles(files => files.filter(file => file.name !== name))
  }

  const removeFileFromRejected = name => {
    setRejected(files => files.filter(({ file }) => file.name !== name))
  }

  return (
    <form onSubmit={onSubmit}>
      {/* dropzone */}
      {!isSending &&
        <div {...getRootProps()}
          className='flex flex-col justify-center items-center p-4 mb-2 rounded-lg border border-dashed border-modelic-gray-600 text-xs text-modelic-gray-100'
        >
          <input {...getInputProps()} />
          <span className='mb-2'>{IcUpload}</span>

          <div className='flex flex-col justify-center items-center space-y-0 mb-1'>
            <p>{lang?.dropzone?.title}</p>
            <p>{lang?.dropzone?.or}</p>
            <p className='underline text-modelic-primary-main '>{lang?.dropzone?.chooseFile}</p>
            <span className='text-[10px] text-modelic-gray-400'>{lang?.dropzone?.types}</span>
          </div>
        </div>
      }

      {/* preview and progress */}
      <ul className='flex flex-col space-y-2 mb-2'>
        {files.map(file => (
          <li key={file?.name}>
            <Item file={file} tag={isSending ? "progress" : "preview"} removeFile={() => removeFileFromPreview(file?.name)} progress={progress[file?.name]} />
          </li>
        ))}
      </ul>

      {/* rejected */}
      <ul className='flex flex-col space-y-2 mb-2'>
        {rejected.map(({ file, errors }) => (
          <li key={file.name}>
            <Item file={file} errors={errors} tag="rejected" removeFile={() => removeFileFromRejected(file.name)} />
          </li>
        ))}
      </ul>

      {/* submit */}
      {!!files.length && !rejected.length && !isSending && <SubmitButton />}

    </form>
  )
}
import React from 'react';
import LoadingBar from 'react-redux-loading-bar';
import { withRouter } from 'react-router-dom';

import TopBar from './../Navs/TopBar';
import ToolbarContainer from '../Tools/ToolbarContainer';

const PrivateLayout = ({ children, location }) => {
    return (
        <>
            <div className=" h-screen px-8 pt-4 pb-16">
                <TopBar />
                {children}
                {location.pathname.includes('viewer') && <ToolbarContainer />}
            </div>
            <LoadingBar className="bg-modelic-primary-light h-1 fixed top-0 z-auto" />
        </>
    );
};

export default withRouter(PrivateLayout);

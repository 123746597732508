import { createContext } from 'react';

export const issueContext = createContext({
    issueView: 'all',
    setIssueView: () => {},
    state: null,
    setState: () => {},
    markup: null,
    setMarkup: () => {},
    setScreenshot: () => {},
    handleCreateMarkup: () => {},
    handleRestoreScreenshot: () => {},
});

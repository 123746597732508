import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { IcIssueListArrow } from '../../share/icons';
import { setAssigneeTeam } from '../../actions/issue';
import locale from '../../utils/localization.json';

const TeamChip = ({ teamsColor, team, hasArrow }) => {
    const [isOpen, setIsOpen] = useState(false);

    const lang = useSelector((state) => state.language);
    const assigneeTeams = useSelector((state) => state.assigneeTeams);

    const dispatch = useDispatch();

    const wrapperRef = useRef(null);

    useEffect(() => {
        // Alert if clicked on outside of element
        function handleClickOutside({ target }) {
            if (wrapperRef.current && !wrapperRef.current.contains(target))
                setIsOpen(false);
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [wrapperRef]);

    return (
        <div className="relative text-modelic-gray-100 text-xs font-medium font-poppins leading-[14px]">
            <button
                className={`pl-2 pr-1 py-0.5 rounded flex items-center ${
                    teamsColor?.find((t) => t.name === team)?.color ||
                    'bg-modelic-gray-700'
                }`}
                disabled={!hasArrow || !assigneeTeams}
                onClick={() => setIsOpen(!isOpen)}
            >
                <div className="pr-1">
                    {team ||
                        (hasArrow && assigneeTeams ? locale[lang].select : '-')}
                </div>
                {hasArrow && assigneeTeams && (
                    <div className={isOpen ? 'rotate-180' : ''}>
                        {IcIssueListArrow}
                    </div>
                )}
            </button>
            {isOpen && (
                <div
                    className={`absolute w-auto min-w-full mt-1 p-2 whitespace-nowrap rounded ${
                        teamsColor?.find((t) => t.name === team)?.color ||
                        'bg-modelic-gray-700'
                    } flex flex-col gap-[10px]`}
                    ref={wrapperRef}
                >
                    {assigneeTeams &&
                        assigneeTeams.map(({ _id, name }) => (
                            <div
                                className="hover:cursor-pointer"
                                key={_id}
                                onClick={() => {
                                    if (name !== team)
                                        dispatch(setAssigneeTeam(_id));
                                    setIsOpen(false);
                                }}
                            >
                                {name}
                            </div>
                        ))}
                    {team && (
                        <div
                            className="w-full hover:cursor-pointer"
                            onClick={() =>
                                dispatch(setAssigneeTeam()) && setIsOpen(false)
                            }
                        >
                            -
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default TeamChip;

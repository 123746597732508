import IssueHeader from './IssueHeader';

const IssueCard = ({ issue, teamsColor }) => {
    return (
        <div className="w-full p-2 bg-modelic-gray-800 rounded border border-modelic-gray-700">
            <IssueHeader issue={issue} teamsColor={teamsColor} />
        </div>
    );
};

export default IssueCard;

import { useSelector } from 'react-redux';

import { IcLoading } from '../../share/icons';
import locale from './../../utils/localization.json';

const ViewerLoader = () => {
    const lang = useSelector((state) => state.language);

    return (
        <div className="w-full h-full absolute flex items-center justify-center bg-modelic-gray-200 bg-opacity-70 text-modelic-gray-800 font-poppins text-lg">
            {locale[lang].wait}
            <span className="scale-50">
                <div className="animate-spin">{IcLoading}</div>
            </span>
        </div>
    );
};

export default ViewerLoader;

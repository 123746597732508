import http from './httpService';

export const addIssue = (body) => {
    return http.post(
        `${process.env.REACT_APP_MODELIC_API}/viewer/issue/add_issue`,
        body,
        {
            headers: { 'Content-Type': 'multipart/form-data' },
        }
    );
};

export const allIssues = (modelId) => {
    return http.get(
        `${process.env.REACT_APP_MODELIC_API}/viewer/issue/all_issues/${modelId}`
    );
};

export const getIssue = (issueId) => {
    return http.get(
        `${process.env.REACT_APP_MODELIC_API}/viewer/issue/${issueId}`
    );
};

export const deleteIssue = (issueId) => {
    return http.delete(
        `${process.env.REACT_APP_MODELIC_API}/viewer/issue/${issueId}`
    );
};

export const updateIssue = (issueId, body) => {
    return http.put(
        `${process.env.REACT_APP_MODELIC_API}/viewer/issue/${issueId}`,
        body
    );
};

export const addMessage = (issueId, text) => {
    return http.post(
        `${process.env.REACT_APP_MODELIC_API}/viewer/issue/add_message/${issueId}`,
        { text }
    );
};

export const getAssigneeTeams = (issueId) => {
    return http.get(
        `${
            process.env.REACT_APP_MODELIC_API
        }/viewer/issue/assignee_teams/?issue_id=${issueId || ''}`
    );
};

export const assignIssue = (issueId, assignee_id) => {
    return http.post(
        `${process.env.REACT_APP_MODELIC_API}/viewer/issue/assign_issue/${issueId}`,
        { assignee_id }
    );
};

export const updateStatus = (issueId, status) => {
    return http.put(
        `${process.env.REACT_APP_MODELIC_API}/viewer/issue/issue_status/${issueId}`,
        { status }
    );
};

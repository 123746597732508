import { IcTickSquare } from '../../icons'

export default function SubmitButton() {
    return (
        <button type='submit' className='w-full flex justify-center items-center space-x-2 px-4 py-2 rounded bg-modelic-primary-main text-modelic-gray-100'>
            <span className='capitalize'>submit</span>
            <span>{IcTickSquare}</span>
        </button>
    )
}

export const getDate = (date) => {
    return new Date(date).toLocaleDateString();
};

export const getTime = (date) => {
    return new Date(date + (date.includes('Z') ? '' : 'Z')).toLocaleTimeString(
        'fr-FR',
        { hour: '2-digit', minute: '2-digit' }
    );
};

import { combineReducers } from 'redux';
import { loadingBarReducer } from 'react-redux-loading-bar';

import { currentModelReducer, modelsReducer } from './model';
import { currentProjectReducer } from './project';
import { viewerReducer, notificationReducer, geometryReducer } from './viewer';
import {
    issuesReducer,
    issuesVisibilityReducer,
    currentIssueReducer,
    assigneeTeamsReducer,
} from './issue';
import { toolbarsReducer } from './toolbar';
import { languageReducer } from './language';
import { pointcloudsReducer } from './pointcloud';

export const reducers = combineReducers({
    models: modelsReducer,
    pointclouds: pointcloudsReducer,
    loadingBar: loadingBarReducer,
    currentProject: currentProjectReducer,
    currentObject: currentModelReducer,
    viewer: viewerReducer,
    notifications: notificationReducer,
    geometries: geometryReducer,
    issues: issuesReducer,
    currentIssue: currentIssueReducer,
    issuesVisible: issuesVisibilityReducer,
    assigneeTeams: assigneeTeamsReducer,
    toolbars: toolbarsReducer,
    language: languageReducer,
});

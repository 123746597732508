export const modelsReducer = (state = [], action) => {
    switch (action.type) {
        case 'INIT_MODEL':
            return [...action.payload];
        case 'ADD_MODEL':
            return [...action.payload];
        case 'UPDATE_MODEL':
            return [...action.payload];
        case 'DELETE_MODEL':
            return [...action.payload];
        case 'TRANSLATE_MODEL':
            return [...action.payload];
        default:
            return state;
    }
};

export const currentModelReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_MODEL':
            return { ...action.payload };
        default:
            return state;
    }
};

import { useState, useEffect, useRef, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IcEdit, IcThreeDots } from '../../share/icons';
import { getDate, getTime } from '../../utils/date';
import { issueContext } from './../context/issueContext';
import { setCurrentIssue } from '../../actions/issue';
import TeamChip from './TeamChip';
import StatusChip from './StatusChip';
import locale from '../../utils/localization.json';
import Modal from './../../share/components/base/Modal';
import { IcCopy } from './../../share/icons';

const IssueHeader = ({ issue, teamsColor }) => {
    const [showMore, setShowMore] = useState(false);

    const lang = useSelector((state) => state.language);
    const viewer = useSelector((state) => state.viewer);
    const assigneeTeams = useSelector((state) => state.assigneeTeams);

    const {
        issueView,
        setIssueView,
        setState,
        setMarkup,
        handleRestoreScreenshot,
    } = useContext(issueContext);

    const wrapperRef = useRef(null);
    const modalRef = useRef(null);

    const dispatch = useDispatch();

    useEffect(() => {
        setState(issue.state);
        setMarkup(issue.markup);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // Alert if clicked on outside of element
        function handleClickOutside({ target }) {
            if (wrapperRef.current && !wrapperRef.current.contains(target))
                setShowMore(false);
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [wrapperRef]);

    const handleShowIssue = async () => {
        try {
            if (issueView === 'all') {
                const currentIssue = await dispatch(
                    setCurrentIssue(issue?._id)
                );

                checkModelView(currentIssue, 'show');
            }
        } catch {}
    };

    const checkModelView = (currentIssue, issueView) => {
        if (
            currentIssue?.view_id ===
            viewer?.model?.getDocumentNode()?.data?.guid
        )
            setIssueView(issueView);
        else modalRef.current.parentNode.click();
    };

    return (
        <>
            <div className="space-y-2 relative">
                {/* IssueHeader */}
                <div className="w-full flex justify-between">
                    <div
                        className={`group max-w-full truncate pr-1 text-modelic-gray-100 text-base font-medium font-poppins leading-tight ${
                            issueView === 'all' ? 'cursor-pointer' : ''
                        }`}
                        onClick={handleShowIssue}
                    >
                        {issue?.title}
                        <div className="absolute top-6 z-50 whitespace-normal break-words rounded-md bg-modelic-gray-900 py-1.5 px-3 border border-modelic-gray-700 font-poppins text-sm font-normal text-modelic-gray-100 hidden group-hover:block">
                            {issue?.title}
                        </div>
                    </div>
                    <StatusChip
                        status={issue?.status}
                        hasArrow={issueView === 'show'}
                    />
                </div>

                {/* Main Section */}
                <div className="w-full flex gap-2">
                    <img
                        className="w-[98px] h-[98px] rounded-lg object-cover cursor-pointer"
                        src={issue?.url || issue?.screenshot}
                        alt="screenshot"
                        onClick={
                            issueView === 'all'
                                ? handleShowIssue
                                : handleRestoreScreenshot
                        }
                    />
                    <div className="w-[calc(100%-106px)] flex flex-col justify-around">
                        <div className="w-full justify-start items-center space-x-2 inline-flex overflow-hidden">
                            <div className="text-modelic-gray-500 text-xs font-normal font-poppins">
                                {locale[lang].user}:
                            </div>
                            <div className="truncate text-modelic-gray-100 text-xs font-poppins leading-none">
                                {issue?.username}
                            </div>
                        </div>
                        <div className="w-full justify-start items-center space-x-2 inline-flex">
                            <div className="text-modelic-gray-500 text-xs font-normal font-poppins leading-3">
                                {locale[lang].team}:
                            </div>
                            <TeamChip
                                teamsColor={teamsColor}
                                team={issue?.team}
                                hasArrow={false}
                            />
                        </div>
                        <div className="w-full flex justify-between items-start">
                            <div className="justify-start items-center space-x-2 inline-flex">
                                <div className="text-modelic-gray-500 text-xs font-normal font-poppins leading-3">
                                    {locale[lang].assignee}:
                                </div>
                                <TeamChip
                                    teamsColor={teamsColor}
                                    team={issue?.assignee}
                                    hasArrow={issueView === 'show'}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer */}
                <div className="flex justify-between items-center">
                    <div className="text-modelic-gray-500 text-[10px] font-normal font-poppins leading-3">
                        {`${getDate(issue?.updated_at)} - ${getTime(
                            issue?.updated_at
                        )}`}
                    </div>
                    <div className="relative flex">
                        <button
                            className={`${
                                issue?.is_editable
                                    ? 'text-modelic-primary-main'
                                    : 'text-modelic-gray-700'
                            }`}
                            disabled={!issue?.is_editable}
                            onClick={() => setShowMore(!showMore)}
                        >
                            {IcThreeDots}
                        </button>
                        {showMore && (
                            <div
                                className="absolute -right-[8px] top-5 shadow z-10"
                                ref={wrapperRef}
                            >
                                <div className="arrow absolute top-2 right-0 rounded-[4px] border border-modelic-gray-600">
                                    <div className="z-10 p-2 pr-6 rounded-[4px] space-y-2 bg-modelic-gray-900">
                                        <button
                                            className="flex gap-2 p-1 text-modelic-primary-lighter hover:text-modelic-primary-main"
                                            onClick={async () => {
                                                try {
                                                    const currentIssue =
                                                        await dispatch(
                                                            setCurrentIssue(
                                                                issue._id
                                                            )
                                                        );
                                                    checkModelView(
                                                        currentIssue,
                                                        'edit'
                                                    );
                                                } catch {}
                                            }}
                                        >
                                            <span className="self-center">
                                                {IcEdit}
                                            </span>
                                            {locale[lang].edit}
                                        </button>
                                        {assigneeTeams && (
                                            <button
                                                className="flex gap-2 p-1 text-modelic-primary-lighter hover:text-modelic-primary-main"
                                                onClick={async () => {
                                                    try {
                                                        const currentIssue =
                                                            await dispatch(
                                                                setCurrentIssue(
                                                                    issue._id
                                                                )
                                                            );
                                                        checkModelView(
                                                            currentIssue,
                                                            'clone'
                                                        );
                                                    } catch {}
                                                }}
                                            >
                                                <span className="self-center">
                                                    {IcCopy}
                                                </span>
                                                {locale[lang].clone}
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {/* Confirm Modal */}
            <div className="h-0">
                <Modal
                    lable={<div ref={modalRef} id={issue._id + '_view'}></div>}
                    title={locale[lang].issueViewConfirmTitle}
                    tag="confirm"
                    callback={() => setIssueView('show')}
                >
                    <div className="text-lg">
                        {locale[lang].issueViewConfirm}
                    </div>
                </Modal>
            </div>
        </>
    );
};

export default IssueHeader;

import { getPublicToken } from './../services/forgeService';
import { analyzePointCloud } from './../services/pointcloudService';
import { errorMessage } from './message';
import locale from './../utils/localization.json';

export const getAccessToken = async (callback) => {
    try {
        const { data } = await getPublicToken();
        callback(data.access_token, data.expires_in);
    } catch (err) {
        // log.error(err);
    }
};

export const onDocumentLoadFailure = (viewerErrorCode, viewerErrorMsg) => {
    console.error(
        'Failed to load manifest [' + viewerErrorCode + '] ' + viewerErrorMsg
    );
    const lang = localStorage.getItem('language');
    if (viewerErrorCode === 9) {
        errorMessage([lang].onDocumentLoadFailureErrorCode9);
    } else {
        errorMessage(locale[lang].onDocumentLoadFailureOtherErrors);
    }
};

export const onLoadModelError = (viewerErrorCode, viewerErrorMsg) => {
    console.error(
        'onLoadModelError() [' + viewerErrorCode + '] ' + viewerErrorMsg
    );
    const lang = localStorage.getItem('language');
    errorMessage(locale[lang].onLoadModelError);
};

export const addModel = (urn, viewer) => {
    return new Promise((resolve, reject) => {
        window.Autodesk.Viewing.Document.load(
            `urn:${urn.replace('_', '/')}`,
            (doc) => {
                const geometries = doc.getRoot().search({ type: 'geometry' });
                const initGeom = geometries[0];
                const svfUrl = doc.getViewablePath(initGeom);
                viewer?.loadModel(
                    svfUrl,
                    {
                        createWireframe: false,
                        applyScaling: 'm',
                        isAEC: true,
                        globalOffset: viewer?.model?.getGlobalOffset(),
                        acmSessionId: doc.getAcmSessionId(svfUrl),
                        // applyRefPoint: true,
                    },
                    (model) => resolve(model),
                    onLoadModelError
                );
                if (viewer === undefined || viewer === null) reject();
            }
        );
    });
};

export const removeModel = (model, viewer) => {
    viewer?.unloadModel(model);
};

export const getAllLeafComponents = (viewer, callback) => {
    try {
        if (viewer)
            viewer?.getObjectTree(function (tree) {
                let leaves = [];
                tree.enumNodeChildren(
                    tree.getRootId(),
                    function (dbId) {
                        if (tree.getChildCount(dbId) === 0) {
                            leaves.push(dbId);
                        }
                    },
                    true
                );

                callback(leaves);
            });
    } catch (err) {
        console.log(err);
    }
};

////////////////////////////////////////////////////////
/// Get Project Base Point of the Model (REVIT ONLY) ///
////////////////////////////////////////////////////////
export const getProjectBasePoint = async (model) => {
    try {
        const basePointData = await getBasePointData(model);

        const eastWestProp = basePointData?.properties?.find(
            (p) => p.attributeName === 'E/W'
        );
        const northSouthProp = basePointData?.properties?.find(
            (p) => p.attributeName === 'N/S'
        );
        const elevProp = basePointData?.properties?.find(
            (p) => p.attributeName === 'Elev'
        );

        // eslint-disable-next-line no-undef
        const eastWestVal = Autodesk.Viewing.Private.convertToDisplayUnits(
            eastWestProp?.displayValue,
            eastWestProp?.type,
            eastWestProp?.units,
            model?.getUnitString()
        );
        // eslint-disable-next-line no-undef
        const northSouthVal = Autodesk.Viewing.Private.convertToDisplayUnits(
            northSouthProp?.displayValue,
            northSouthProp?.type,
            northSouthProp?.units,
            model?.getUnitString()
        );
        // eslint-disable-next-line no-undef
        const elevVal = Autodesk.Viewing.Private.convertToDisplayUnits(
            elevProp?.displayValue,
            elevProp?.type,
            elevProp?.units,
            model?.getUnitString()
        );

        // eslint-disable-next-line no-undef
        const basePoint = new THREE.Vector3(
            eastWestVal?.displayValue,
            northSouthVal?.displayValue,
            elevVal?.displayValue
        );

        return basePoint;
    } catch {
        return null;
    }
};
const getBasePointData = async (model, category = 'Revit Base Point') => {
    return new Promise(async (resolve, reject) => {
        try {
            const found = await searchAsync(model, category, ['Category'], {
                searchHidden: true,
            });
            if (!found || found.length <= 0)
                return reject('Base point not found');

            const result = await getBulkProperties2Async(
                found,
                { propFilter: ['N/S', 'E/W', 'Elev', 'Angle to True North'] },
                model
            );

            if (!result) return reject('Base point not found');

            const data = result[0];
            return resolve(data);
        } catch (err) {
            reject(err);
        }
    });
};
const searchAsync = async (model, text, attributeNames, options) => {
    return new Promise((resolve, reject) => {
        model?.search(text, resolve, reject, attributeNames, options);
    });
};
const getBulkProperties2Async = async (dbIds, options, model) => {
    return new Promise((resolve, reject) => {
        model?.getBulkProperties2(
            dbIds,
            options,
            (result) => resolve(result),
            (error) => reject(error)
        );
    });
};
////////////////////////////////////////////////////////

//////////////////////////////////////
/// MODEL Transparency and Opacity ///
//////////////////////////////////////
export const handleTransparency = (viewer, dbIds, elements, value) => {
    var fragList = viewer?.model?.getFragmentList();
    viewer?.model?.unconsolidate();
    dbIds
        .filter((i) => !elements.includes(i))
        .forEach((dbId) => {
            const fragIds = nodeIdToFragIds(viewer?.model, dbId);
            fragIds.forEach((fragId) => {
                let material = fragList?.getMaterial(fragId).clone();
                if (material) {
                    material.opacity = value;
                    material.transparent = true;
                    material.needsUpdate = true;
                }
                viewer?.impl
                    .matman()
                    .addMaterial('myCustomMaterial-' + fragId, material, true);
                viewer?.model?.getFragmentList().setMaterial(fragId, material);

                viewer?.impl?.invalidate(true);
            });
        });
};
export const overrideOpacity = (viewer, dbIds) => {
    const fragList = viewer?.model?.getFragmentList();

    dbIds.forEach((dbId) => {
        const fragIds = nodeIdToFragIds(viewer?.model, dbId);

        fragIds.forEach((fragId) => {
            const material = fragList?.getMaterial(fragId);

            if (material) {
                material.opacity = 0.1;
                material.transparent = true;
                material.needsUpdate = true;
            }
        });
    });
    viewer?.impl?.invalidate(true);
    viewer?.model?.unconsolidate();

    return true;
};
const nodeIdToFragIds = (model, nodeId) => {
    var instanceTree = model?.getData()?.instanceTree;

    var fragIds = [];
    instanceTree?.enumNodeFragments(nodeId, (fragId) => {
        fragIds = [...fragIds, fragId];
    });

    return fragIds;
};
//////////////////////////////////////

//////////////////////////////////////
/// Compare Point Cloud with Model ///
//////////////////////////////////////
export const startAnalysis = (viewer, name) => {
    return new Promise(async (resolve, reject) => {
        // const instanceTree = viewer?.model?.getData()?.instanceTree;
        let meshes = [];

        getAllLeafComponents(viewer, (dbIds) => {
            viewer?.model?.getPropertySetAsync(dbIds).then(async (a) => {
                dbIds = Object.entries(a.map)
                    .find(([, p]) => p[0].displayName === 'Category')[1]
                    .filter(
                        (e) =>
                            e.displayValue !== 'Revit Level' &&
                            !e.displayValue.includes('Line')
                    )
                    .map((e) => e.dbId);

                for (let i = 0; i < dbIds.length; i++) {
                    const dbid = dbIds[i];
                    // const nodeName = instanceTree?.getNodeName(dbid);
                    // if (nodeName?.includes('[')) {
                    const fragIds = await getFragIds(viewer?.model, dbid);
                    meshes = [...meshes].concat(
                        fragIds.map((fragId) => {
                            return getMeshFromRenderProxy(
                                viewer?.impl?.getRenderProxy(
                                    viewer?.model,
                                    fragId
                                )
                            );
                        })
                    );
                    // }
                }

                try {
                    const globalOffset = viewer?.model?.getGlobalOffset();
                    const projectBasePoint = await getProjectBasePoint(
                        viewer?.model
                    );
                    const totalOffset = {
                        x: globalOffset?.x + projectBasePoint?.x,
                        y: globalOffset?.y + projectBasePoint?.y,
                        z: globalOffset?.z + projectBasePoint?.z,
                    };

                    // analyzePointClouds(viewer, name, totalOffset, meshes);

                    const { status } = await analyzePointCloud({
                        meshes,
                        name,
                        totalOffset,
                    });
                    if (status === 200) {
                        resolve();
                    }
                } catch (err) {
                    reject(err);
                }
            });
        });
    });
};
const getMeshFromRenderProxy = (render_proxy) => {
    let matrix = render_proxy.matrixWorld;
    let geometry = render_proxy.geometry;
    let attributes = geometry.attributes;

    let vA = new window.THREE.Vector3();
    let vB = new window.THREE.Vector3();
    let vC = new window.THREE.Vector3();

    let geo = new window.THREE.Geometry();
    let iv = 0;

    if (attributes.index !== undefined) {
        let indices = attributes.index.array || geometry.ib;
        let positions = geometry.vb ? geometry.vb : attributes.position.array;
        let stride = geometry.vb ? geometry.vbstride : 3;
        let offsets = geometry.offsets;
        if (!offsets || offsets.length === 0) {
            offsets = [{ start: 0, count: indices.length, index: 0 }];
        }
        for (let oi = 0, ol = offsets.length; oi < ol; ++oi) {
            let start = offsets[oi].start;
            let count = offsets[oi].count;
            let index = offsets[oi].index;
            for (let i = start, il = start + count; i < il; i += 3) {
                let a = index + indices[i];
                let b = index + indices[i + 1];
                let c = index + indices[i + 2];

                vA.fromArray(positions, a * stride);
                vB.fromArray(positions, b * stride);
                vC.fromArray(positions, c * stride);

                vA.applyMatrix4(matrix);
                vB.applyMatrix4(matrix);
                vC.applyMatrix4(matrix);

                geo.vertices.push(new window.THREE.Vector3(vA.x, vA.y, vA.z));
                geo.vertices.push(new window.THREE.Vector3(vB.x, vB.y, vB.z));
                geo.vertices.push(new window.THREE.Vector3(vC.x, vC.y, vC.z));
                geo.faces.push(new window.THREE.Face3(iv, iv + 1, iv + 2));
                iv = iv + 3;
            }
        }
    }

    geo.computeFaceNormals();
    geo.computeVertexNormals();
    geo.computeBoundingBox();

    const bGeo = new window.THREE.BufferGeometry().fromGeometry(geo);
    bGeo.computeFaceNormals();
    bGeo.computeVertexNormals();
    bGeo.computeBoundingBox();

    let mat = new window.THREE.MeshBasicMaterial({
        color: 0xffff00,
        side: window.THREE.DoubleSide,
    });

    let mesh = new window.THREE.Mesh(bGeo, mat);

    // mesh.matrixWorldNeedsUpdate = true;
    // mesh.matrixAutoUpdate = false;
    // mesh.frustumCulled = false;

    return mesh;
};
const getFragIds = (model, dbIds) => {
    return new Promise(async (resolve, reject) => {
        try {
            const dbIdArray = Array.isArray(dbIds) ? dbIds : [dbIds];

            const instanceTree = model?.getData()?.instanceTree;

            const leafIds = await getLeafNodes(model, dbIdArray);

            let fragIds = [];

            for (var i = 0; i < leafIds.length; ++i) {
                instanceTree?.enumNodeFragments(leafIds[i], (fragId) => {
                    fragIds.push(fragId);
                });
            }

            return resolve(fragIds);
        } catch (ex) {
            return reject(ex);
        }
    });
};
const getLeafNodes = (model, dbIds) => {
    return new Promise((resolve, reject) => {
        try {
            const instanceTree = model?.getData()?.instanceTree;

            dbIds = dbIds || instanceTree?.getRootId();

            const dbIdArray = Array.isArray(dbIds) ? dbIds : [dbIds];

            let leafIds = [];

            const getLeafNodesRec = (id) => {
                var childCount = 0;

                instanceTree?.enumNodeChildren(id, (childId) => {
                    getLeafNodesRec(childId);

                    ++childCount;
                });

                if (childCount === 0) {
                    leafIds.push(id);
                }
            };

            for (var i = 0; i < dbIdArray.length; ++i) {
                getLeafNodesRec(dbIdArray[i]);
            }

            return resolve(leafIds);
        } catch (ex) {
            return reject(ex);
        }
    });
};
// eslint-disable-next-line no-unused-vars
const analyzePointClouds = (viewer, name, totalOffset, meshes) => {
    const extension = viewer?.getExtension('PointCloudExtension');
    if (!extension) return console.log('extension not loaded!!!');
    const points = extension['POINTCLOUD_' + name.split(/\.(?=[^.]+$)/)[0]];

    if (!points) return console.log('point cloud not loaded!!!');

    const updatedPoints = [];

    viewer?.impl?.removeOverlayScene('line');
    viewer?.impl?.createOverlayScene('line');

    points.forEach(async (p, i) => {
        const point = {
            x: p.X - totalOffset.x,
            y: p.Y - totalOffset.y,
            z: p.Z - totalOffset.z,
        };

        const filteredMeshes = meshes.filter((mesh) => {
            const bb = mesh.geometry.boundingBox;
            return (
                point.x >= bb.min.x - 0.2 &&
                point.y >= bb.min.y - 0.2 &&
                point.z >= bb.min.z - 0.2 &&
                point.x <= bb.max.x + 0.2 &&
                point.y <= bb.max.y + 0.2 &&
                point.z <= bb.max.z + 0.2
            );
        });

        // var geom = new window.THREE.SphereGeometry(10, 8, 8);
        // var material = new window.THREE.MeshBasicMaterial({ color: 0xff0000 });
        // var sphereMesh = new window.THREE.Mesh(geom, material);
        // sphereMesh.position.set(1, 2, 3);

        // viewer.overlays.addMesh(filteredMeshes[0], 'line');
        // viewer.overlays.addMesh(filteredMeshes[1], 'line');

        const distances = await rayTrace(point, filteredMeshes, viewer);
        updatedPoints.push({
            ...p,
            D:
                distances.length > 1
                    ? Math.round(Math.min(...distances) * 1000).toString()
                    : '201',
        });

        if (i === points.length - 1) {
            extension['POINTCLOUD_' + name.split(/\.(?=[^.]+$)/)[0]] =
                updatedPoints;
        }
    });
};
const rayTrace = (point, meshes, viewer) => {
    return new Promise((resolve, reject) => {
        // let target;
        let origin = new window.THREE.Vector3(point.x, point.y, point.z);

        let max = 1; // 3 * 3 * 3 = 27 - 1 --> rays count for each point

        let distances = [];

        for (let x = -max; x <= max; x += 1) {
            for (let y = -max; y <= max; y += 1) {
                for (let z = -max; z <= max; z += 1) {
                    if (x !== 0 || y !== 0 || z !== 0) {
                        // target = new window.THREE.Vector3(
                        //     origin.x + x,
                        //     origin.y + y,
                        //     origin.z + z
                        // );

                        // drawLine(origin, target, viewer);

                        let direction = new window.THREE.Vector3(x, y, z);
                        direction.normalize();

                        let raycaster = new window.THREE.Raycaster(
                            origin,
                            direction,
                            0,
                            0.2 // max 20 cm
                        );

                        // threejs raycasting
                        let intersects = raycaster.intersectObjects(
                            meshes,
                            true
                        );
                        intersects.forEach((i) => {
                            distances.push(i.distance);
                        });

                        // // forge built-in raycasting
                        // const intersect = viewer?.model?.rayIntersect(
                        //     raycaster,
                        //     true
                        // );
                        // if (intersect) distances.push(intersect.distance);
                    }
                }
            }
        }

        resolve(distances);
    });
};
// eslint-disable-next-line no-unused-vars
const drawLine = (start, end, viewer) => {
    const material = new window.THREE.LineBasicMaterial({
        color: 0x0000ff,
    });

    const points = [];
    points.push(new window.THREE.Vector3(start.x, start.y, start.z));
    points.push(new window.THREE.Vector3(end.x, end.y, end.z));

    const geometry = new window.THREE.BufferGeometry().setFromPoints(points);

    const line = new window.THREE.Line(geometry, material);

    viewer?.impl?.addOverlay('line', line);
};
//////////////////////////////////////

////////////////////////
/// Restore Sections ///
////////////////////////
export const restoreSectionBox = (viewer, cutplanes) => {
    let box = new window.THREE.Box3();
    for (const cutplane of cutplanes) {
        const normal = new window.THREE.Vector3(
            cutplane[0],
            cutplane[1],
            cutplane[2]
        );
        const offset = cutplane[3];
        const pointOnPlane = normal.clone().negate().multiplyScalar(offset);
        box.expandByPoint(pointOnPlane);
    }
    const sectionExt = viewer.getExtension('Autodesk.Section');
    sectionExt.setSectionBox(box);
};

export const restoreSectionPlane = (viewer, cutplane) => {
    const normal = new window.THREE.Vector3(
        cutplane[0],
        cutplane[1],
        cutplane[2]
    );
    const offset = cutplane[3];
    const pointOnPlane = normal.clone().negate().multiplyScalar(offset);

    const sectionExt = viewer.getExtension('Autodesk.Section');
    sectionExt.setSectionPlane(normal, pointOnPlane);
};
////////////////////////////////////////////////////////
import http from './httpService';

export const uploadObject = (body, projectId, axiosConfig) => {
    return http.post(
        `${process.env.REACT_APP_OSS_API}/object/${projectId}`,
        body,
        axiosConfig
    );
};

export const uploadChunk = (body, projectId, axiosConfig) => {
    return http.post(
        `${process.env.REACT_APP_OSS_API}/chunk/${projectId}`,
        body,
        axiosConfig
    );
};

export const getModels = (projectId) => {
    return http.get(`${process.env.REACT_APP_OSS_API}/objects/${projectId}`);
};

export const downloadModels = () => {
    return http.get(
        `${process.env.REACT_APP_MODELIC_API}/viewer/model/download`
    );
};

export const deleteModel = (body, projectId) => {
    return http.delete(`${process.env.REACT_APP_OSS_API}/object/${projectId}`, {
        data: body,
    });
};

import { useEffect } from 'react';

const PageRedirector = () => {
    useEffect(() =>
        window.location.replace(process.env.REACT_APP_MODELIC_REDIRECT)
    );
    return null;
};

export default PageRedirector;

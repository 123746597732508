export const pointcloudsReducer = (state = [], action) => {
    switch (action.type) {
        case 'INIT_POINTCLOUD':
            return [...action.payload];
        case 'ADD_POINTCLOUD':
            return [...action.payload];
        case 'UPDATE_POINTCLOUD':
            return [...action.payload];
        case 'PREPARE_POINTCLOUD':
            return [...action.payload];
        case 'DELETE_POINTCLOUD':
            return [...action.payload];
        default:
            return state;
    }
};

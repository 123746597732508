export const viewerReducer = (state = {}, action) => {
    switch (action.type) {
        case 'INIT_VIEWER':
            return action.payload;
        default:
            return state;
    }
};

export const notificationReducer = (state = [], action) => {
    switch (action.type) {
        case 'UPDATE_NOTIFICATION':
            return [...action.payload];
        default:
            return state;
    }
};

export const geometryReducer = (state = [], action) => {
    switch (action.type) {
        case 'INIT_GEOMETRIES':
            return [...action.payload];
        default:
            return state;
    }
};

const ObjectCardItem = ({ icon, title, component }) => {
    return (
        <div className="items-center gap-1.5 inline-flex">
            <div className="text-modelic-primary-lighter">{icon}</div>
            {title && (
                <div className="text-modelic-primary-lighter text-xs font-normal font-poppins leading-3">
                    {title}:
                </div>
            )}
            {component}
        </div>
    );
};

export default ObjectCardItem;

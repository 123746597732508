import React from 'react';

const ToolContainer = ({ children }) => {
    return (
        <div className="w-full h-[calc(100%-48px)] overflow-auto px-4 py-6">
            {children}
        </div>
    );
};

export default ToolContainer;

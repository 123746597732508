import React from 'react'
import { IcDoc, IcClose, IcError } from '../../icons'
import Download from './Download'
import Delete from './Delete'

export default function Item({ file, errors, tag, removeFile, access, deleteHandler, progress }) {
  return (
    <>
      <div className={`w-full px-6 py-2 flex justify-between rounded-lg border ${tag === 'rejected' ? 'border-modelic-error-dark' : 'border-modelic-gray-500'} mb-1 bg-modelic-gray-800`}>
        <div className='w-full flex space-x-2'>
          <span>{IcDoc}</span>
          <div className='w-full flex flex-col'>
            <span className='w-2/3 text-xs break-words pb-1'>{file.name}</span>
            {file.size && <span className='text-xs text-modelic-gray-400 mb-1'>{convertKbToMb(file.size)} mb</span>}
            {tag === 'list' && <span className='text-xs text-modelic-gray-400 mb-1'>{dateToLocal(file.lastModified)}</span>}
            {tag === 'progress' &&
              <div className='flex justify-between items-center'>
                <div className="w-[90%] h-1 bg-gray-200 rounded-full">
                  <div className="bg-modelic-primary-main h-1 rounded-full" style={{ width: `${progress - 1}%` }}></div>
                </div>
                <div className='text-xs'>{progress - 1}%</div>
              </div>
            }
          </div>
        </div>
        <div className='flex space-x-1'>
          {tag === 'list' && access?.download && <Download file={file} />}
          {access?.delete && <Delete name={[file?.name]} handler={deleteHandler} />}
          {['preview', 'rejected'].includes(tag) && <button className={`${tag === 'rejected' ? 'text-modelic-error-dark' : ''}`} onClick={removeFile}>{IcClose}</button>}
        </div>
      </div>

      {/* error */}
      {errors && <ul>
        {errors.map(error => (
          <li key={error.code} className='flex flex-row justify-start items-center space-x-2 text-xs text-modelic-error-dark mb-1'>
            <span className=''>{IcError}</span>
            <span>{error.message}</span>
          </li>
        ))}
      </ul>
      }
    </>
  )
}

// file size convertor from kb to mb
export function convertKbToMb(size) {
  return (((size / 1024 / 1024) * 100) / 100).toFixed(3)
}

export function dateToLocal(data) {
  return new Date(data).toLocaleString()
}

import { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { IcIssueBackArrow, IcNote, IcSend } from '../../share/icons';
import IssueHeader from './IssueHeader';
import TeamChip from './TeamChip';
import { getTime, getDate } from '../../utils/date';
import { issueContext } from './../context/issueContext';
import {
    addNewMessage,
    emptyAllTeams,
    getAllTeams,
} from './../../actions/issue';
import locale from '../../utils/localization.json';

const IssueSingle = ({ teamsColor }) => {
    const lang = useSelector((state) => state.language);
    const currentIssue = useSelector((state) => state.currentIssue);
    const viewer = useSelector((state) => state.viewer);
    const { closeToolbar } = useSelector((state) => state.toolbars);

    const {
        setIssueView,
        setState,
        setMarkup,
        state,
        handleRestoreScreenshot,
    } = useContext(issueContext);

    const [message, setMessage] = useState('');

    const dispatch = useDispatch();

    useEffect(() => {
        setState(currentIssue.state);
        setMarkup(currentIssue.markup);
        dispatch(getAllTeams());
        return () => dispatch(emptyAllTeams());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => handleRestoreScreenshot(), [state]);

    return (
        <div className="w-full h-full bg-modelic-gray-800 rounded border border-modelic-gray-700 flex flex-col justify-between">
            <button
                className="absolute top-3 right-7 flex items-center gap-2 text-modelic-gray-400 hover:text-modelic-primary-main text-sm font-medium font-poppins leading-none"
                onClick={() => {
                    setIssueView('all');

                    let markupExt = viewer?.getExtension(
                        'Autodesk.Viewing.MarkupsCore'
                    );
                    markupExt?.leaveEditMode();
                    markupExt?.hide();

                    closeToolbar?.setVisible(false);
                }}
            >
                {IcIssueBackArrow}
                {locale[lang].back}
            </button>
            <div className="pb-20 h-full overflow-auto">
                <div className="px-2 py-2">
                    <IssueHeader issue={currentIssue} teamsColor={teamsColor} />
                </div>
                <div className="px-2 flex flex-col gap-2">
                    <div className="flex items-center gap-2 text-modelic-primary-lighter">
                        {IcNote} {locale[lang].description}:
                    </div>
                    <div className="text-modelic-gray-500 text-base font-normal font-poppins leading-tight">
                        {currentIssue.description}
                    </div>
                </div>
                {currentIssue.messages?.map((message, index) => (
                    <div key={message._id}>
                        {index === 0 ? (
                            <div className="w-full h-0 my-4 border-t border-modelic-gray-700" />
                        ) : (
                            <div className="w-full h-0 my-4 border-t border-dashed border-modelic-gray-700" />
                        )}
                        <div className="px-2 flex flex-col gap-2">
                            <div className="flex justify-start items-start gap-2">
                                <div>
                                    <div className="w-auto text-modelic-gray-100 text-sm font-normal font-poppins leading-none">
                                        {message.username}
                                    </div>
                                    <div className="mt-1 min-w-[115px] text-modelic-gray-600 text-xs font-normal font-poppins leading-[14px]">
                                        {`${getDate(
                                            message.created_at
                                        )} - ${getTime(message.created_at)}`}
                                    </div>
                                </div>
                                <TeamChip
                                    teamsColor={teamsColor}
                                    team={message.team}
                                    hasArrow={false}
                                />
                            </div>
                            <div className="text-modelic-gray-500 text-base font-normal font-poppins leading-tight">
                                {message.text}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="w-full relative bottom-0 flex items-center">
                <div className="h-20 w-full absolute -top-20 bg-gradient-to-t from-modelic-gray-800/100 to-modelic-gray-800/0"></div>
                <button
                    className="absolute right-2 top-2.5 text-modelic-primary-lighter hover:text-modelic-primary-main"
                    onClick={async () => {
                        if (message)
                            try {
                                await dispatch(addNewMessage(message));
                                setMessage('');
                            } catch {}
                    }}
                >
                    {IcSend}
                </button>
                <input
                    className="w-full py-1 pl-2 pr-9 outline-0 border-t-2 border-modelic-primary-main bg-modelic-gray-800 text-modelic-gray-100 text-sm font-medium font-poppins leading-8 self-center"
                    placeholder={locale[lang].enterMessage}
                    onChange={(e) => setMessage(e.target.value)}
                    value={message}
                />
            </div>
        </div>
    );
};

export default IssueSingle;

import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { IcModel, IcModelFill, IcPointCloud } from '../../share/icons';
import locale from './../../utils/localization.json';

const Information = ({ location }) => {
    const lang = useSelector((state) => state.language);
    const { permissions } = useSelector((state) => state.currentProject);

    return (
        <div className="h-full px-7 pt-7 pb-8 xl:px-8 xl:pt-8 xl:pb-10 w-full bg-modelic-gray-900 rounded-lg flex-col justify-start items-start gap-6 inline-flex">
            {permissions?.model?.download && (
                <Link
                    to="/project/models"
                    className={`self-stretch px-4 py-2 ${
                        location.pathname.includes('/models')
                            ? 'bg-modelic-gray-800 rounded-lg shadow text-modelic-primary-main'
                            : 'text-modelic-primary-lighter'
                    }  justify-start items-center gap-2 inline-flex hover:text-modelic-primary-main`}
                >
                    <div className="w-6 h-6 relative ">
                        {location.pathname.includes('/models')
                            ? IcModelFill
                            : IcModel}
                    </div>
                    <div className="text-center text-base xl:text-lg font-medium font-poppins leading-snug capitalize">
                        {locale[lang].models}
                    </div>
                </Link>
            )}
            {permissions?.point_cloud?.download && (
                <Link
                    to="/project/pointclouds"
                    className={`self-stretch px-4 py-2 ${
                        location.pathname.includes('/pointclouds')
                            ? 'bg-modelic-gray-800 rounded-lg shadow text-modelic-primary-main'
                            : 'text-modelic-primary-lighter'
                    }  justify-start items-center gap-2 inline-flex hover:text-modelic-primary-main`}
                >
                    <div className="w-6 h-6 relative">{IcPointCloud}</div>
                    <div className="text-center text-base xl:text-lg font-medium font-poppins leading-snug capitalize">
                        {locale[lang].pointClouds}
                    </div>
                </Link>
            )}
        </div>
    );
};

export default withRouter(Information);

import http from './httpService';

export const getPointClouds = () => {
    return http.get(
        `${process.env.REACT_APP_MODELIC_API}/viewer/pointcloud/download`
    );
};

export const uploadPointCloud = (file, config) => {
    return http.post(
        `${process.env.REACT_APP_MODELIC_API}/viewer/pointcloud/upload`,
        file,
        config
    );
};

export const deletePointCloud = (filename) => {
    return http.delete(
        `${process.env.REACT_APP_MODELIC_API}/viewer/pointcloud/delete`,
        {
            data: [filename],
        }
    );
};

export const getPointCloud = (url, config) => {
    return http.get(url, config);
};

export const analyzePointCloud = (body) => {
    return http.post(
        `${process.env.REACT_APP_OSS_API}/pointcloud/analyze`,
        body
    );
};

export const cancelAnalysis = (body) => {
    return http.post(
        `${process.env.REACT_APP_OSS_API}/pointcloud/analyze/cancel`,
        body
    );
};

export const clearAnalysis = (body) => {
    return http.post(
        `${process.env.REACT_APP_MODELIC_API}/viewer/pointcloud/rename`,
        body
    );
};


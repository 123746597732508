const ItemContainer = ({ showTool, heading, children }) => {
    return (
        <div
            className={`fixed ${
                showTool ? 'right-11' : '-right-1/4'
            } w-1/4 h-full bg-modelic-gray-900 border-l-2 border-modelic-primary-main transition-all duration-300`}
        >
            <div className="w-full h-12 py-2.5 pl-2.5 border-b-2 border-modelic-primary-main">
                <div className="h-full capitalize text-modelic-gray-100 text-2xl font-poppins leading-7">
                    {heading}
                </div>
            </div>
            {children}
        </div>
    );
};

export default ItemContainer;

import React, { useContext, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { mainContext } from '../context/mainContext';
import { getDate, getTime } from './../../utils/date';
import locale from './../../utils/localization.json';
import { IcDelete, IcDeleteHover, IcEye, IcSearch } from '../../share/icons';
import { getIcon } from './../../utils/fileTypes';
import Download from './../../share/components/CDN/Download';
import Modal from './../../share/components/base/Modal';
import { setCurrentModel } from '../../actions/model';
import { handleModelDelete } from './../../actions/model';
import { handlePointCloudDelete } from './../../actions/pointcloud';

const ModelsTable = ({ location }) => {
    const models = useSelector((state) => state.models);
    const pointclouds = useSelector((state) => state.pointclouds);
    const lang = useSelector((state) => state.language);
    const { permissions } = useSelector((state) => state.currentProject);

    const [objects, setObjects] = useState([]);
    const [search, setSearch] = useState('');

    const dispatch = useDispatch();

    const { lunchViewer } = useContext(mainContext);

    useEffect(() => {
        if (location.pathname.includes('models'))
            setObjects([
                ...models.filter(({ name }) =>
                    name?.toLowerCase().includes(search)
                ),
            ]);
        else
            setObjects([
                ...pointclouds.filter(({ name }) =>
                    name?.toLowerCase().includes(search)
                ),
            ]);
    }, [location.pathname, models, pointclouds, search]);

    useEffect(() => {
        setSearch('');
    }, [location.pathname, models, pointclouds]);

    return (
        <div className="h-auto max-h-full w-full grow rounded border border-modelic-gray-800 flex-col justify-start items-start">
            <div className="w-full h-10 pl-6 pr-4 border-b border-modelic-gray-800 bg-modelic-gray-900 inline-flex justify-between">
                <div className="text-modelic-gray-100 text-base capitalize font-medium font-poppins leading-tight self-center">
                    {location.pathname.includes('models')
                        ? locale[lang].models
                        : locale[lang].pointClouds}
                </div>
                <div className="w-2/3 sm:w-2/5 md:w-1/3 xl:w-1/4 relative inline-flex">
                    <div className="absolute right-2 top-[10px] text-modelic-gray-400">
                        {IcSearch}
                    </div>
                    <input
                        id="filename_search"
                        className="h-8 w-full p-2 pr-8 outline-0 rounded border border-modelic-gray-700 bg-modelic-gray-900 text-modelic-gray-400 text-[10px] sm:text-xs font-medium font-poppins leading-8 self-center"
                        placeholder={locale[lang].search}
                        onChange={(e) =>
                            setSearch(e.target.value?.toLowerCase())
                        }
                        value={search}
                    />
                </div>
            </div>
            <div className="h-[calc(100%-40px)] lg:max-h-[calc(100vh-162px)] overflow-auto">
                <table
                    id="models_table"
                    className="w-full table-auto border-collapse"
                    role="grid"
                >
                    <thead>
                        <tr
                            className={
                                'sticky top-0 h-10 capitalize bg-modelic-dark text-modelic-primary-light text-sm font-poppins leading-none'
                            }
                        >
                            <th
                                className="px-8 py-3 font-medium "
                                aria-controls="models_table"
                                rowSpan="1"
                                colSpan="1"
                            >
                                {locale[lang].fileFormat}
                            </th>

                            <th
                                className="px-8 py-3 font-medium"
                                aria-controls="models_table"
                                rowSpan="1"
                                colSpan="1"
                            >
                                {locale[lang].fileName}
                            </th>

                            <th
                                className="px-8 py-3 font-medium"
                                aria-controls="models_table"
                                rowSpan="1"
                                colSpan="1"
                            >
                                {locale[lang].date}
                            </th>

                            {location.pathname.includes('/models') && (
                                <th
                                    className="px-8 py-3 font-medium"
                                    aria-controls="models_table"
                                    rowSpan="1"
                                    colSpan="1"
                                >
                                    {locale[lang].version}
                                </th>
                            )}
                            {(location.pathname.includes('/models') ||
                                (location.pathname.includes('/pointclouds') &&
                                    permissions?.point_cloud?.delete)) && (
                                <th
                                    className="px-8 py-3 font-medium w-40"
                                    tabIndex="0"
                                    aria-controls="models_table"
                                    rowSpan="1"
                                    colSpan="1"
                                >
                                    {locale[lang].actions}
                                </th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {objects?.length === 0 && (
                            <tr className="bg-modelic-gray-900 h-16 text-modelic-gray-100 text-sm font-normal font-poppins text-center">
                                <td className="py-3" colSpan="5">
                                    {location.pathname.includes('models')
                                        ? locale[lang].noModel
                                        : locale[lang].noPointCloud}
                                </td>
                            </tr>
                        )}
                        {objects?.map((object) => (
                            <tr
                                className="bg-modelic-gray-900 border-t border-modelic-gray-800 h-16 text-modelic-gray-100 text-sm font-normal font-poppins text-center"
                                key={
                                    object.urn ||
                                    object.filename ||
                                    object.name + object.type + object.version
                                }
                                role="row"
                            >
                                <td className="px-px py-3 inline-flex">
                                    {getIcon(object.type)}
                                </td>
                                <td className="px-px py-3">{object.name}</td>
                                <td className="px-px py-3">{`${getDate(
                                    object.date
                                )} - ${getTime(object.date)}`}</td>
                                {location.pathname.includes('/models') && (
                                    <td className="px-px py-3">
                                        <div className="h-8 px-4 py-2 bg-modelic-info-darker rounded-lg border border-modelic-primary-main justify-center items-center gap-2.5 inline-flex">
                                            <div className="text-modelic-primary-main text-sm font-medium font-['Poppins'] leading-none">
                                                {object.version}
                                            </div>
                                        </div>
                                    </td>
                                )}
                                <td className="px-px py-3 h-16 justify-center items-center gap-6 inline-flex">
                                    {object.progress < 100 ? (
                                        locale[lang].processing +
                                        ' : ' +
                                        object.progress +
                                        ' %'
                                    ) : (
                                        <>
                                            {location.pathname.includes(
                                                '/models'
                                            ) &&
                                                permissions?.model
                                                    ?.download && (
                                                    <Download
                                                        file={{
                                                            name: `${object.name}.${object.type}`,
                                                            url: object.url,
                                                        }}
                                                    />
                                                )}
                                            {((location.pathname.includes(
                                                '/models'
                                            ) &&
                                                permissions?.model?.delete) ||
                                                (location.pathname.includes(
                                                    '/pointclouds'
                                                ) &&
                                                    permissions?.point_cloud
                                                        ?.delete)) && (
                                                <Modal
                                                    lable={
                                                        <div className="group text-modelic-error-dark">
                                                            {IcDelete}
                                                            {IcDeleteHover}
                                                        </div>
                                                    }
                                                    title={`${
                                                        locale[lang].delete
                                                    } ${
                                                        location.pathname.includes(
                                                            '/models'
                                                        )
                                                            ? locale[lang].model
                                                            : locale[lang]
                                                                  .pointCloud
                                                    }`}
                                                    tag="delete"
                                                    callback={() => {
                                                        dispatch(
                                                            setCurrentModel(
                                                                object
                                                            )
                                                        );
                                                        if (
                                                            location.pathname.includes(
                                                                '/models'
                                                            )
                                                        )
                                                            dispatch(
                                                                handleModelDelete()
                                                            );
                                                        else
                                                            dispatch(
                                                                handlePointCloudDelete()
                                                            );
                                                    }}
                                                >
                                                    <div className="text-lg">
                                                        {
                                                            locale[lang]
                                                                .deleteObjectBody1
                                                        }
                                                        {location.pathname.includes(
                                                            '/models'
                                                        ) && (
                                                            <span className="italic">
                                                                {` "${locale[lang].deleteObjectBody2} ${object.version}" `}
                                                            </span>
                                                        )}
                                                        {location.pathname.includes(
                                                            '/models'
                                                        )
                                                            ? locale[lang]
                                                                  .deleteObjectBody3
                                                            : locale[lang]
                                                                  .pointCloud}
                                                        <span className="italic">
                                                            {` "${object.name}.${object.type}"`}
                                                            {
                                                                locale[lang]
                                                                    .deleteObjectBody4
                                                            }
                                                        </span>
                                                    </div>
                                                    <div className="text-lg">
                                                        {
                                                            locale[lang]
                                                                .deleteObjectBody5
                                                        }
                                                    </div>
                                                </Modal>
                                            )}

                                            {location.pathname.includes(
                                                'models'
                                            ) &&
                                                permissions?.model
                                                    ?.download && (
                                                    <button
                                                        className="w-5 h-5 text-modelic-primary-lighter hover:text-modelic-primary-main"
                                                        onClick={() =>
                                                            lunchViewer(object)
                                                        }
                                                    >
                                                        {IcEye}
                                                    </button>
                                                )}
                                        </>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default withRouter(ModelsTable);

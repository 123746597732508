import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { Helmet } from 'react-helmet';

import { getAllModels } from '../../actions/model';
import ModelsTable from './ModelsTable';
import { getAllPointClouds } from '../../actions/pointcloud';
import Content from '../common/Content';

const Models = ({ location, title }) => {
    const dispatch = useDispatch();
    const currentProject = useSelector((state) => state.currentProject);

    useEffect(() => {
        if (!isEmpty(currentProject)) {
            if (location.pathname.includes('/models')) {
                dispatch(getAllModels());
            } else if (location.pathname.includes('/pointclouds'))
                dispatch(getAllPointClouds());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);
    return (
        <>
            <Helmet>
                <title>MODELIC | {title}</title>
            </Helmet>
            <Content>
                <ModelsTable />
            </Content>
        </>
    );
};

export default withRouter(Models);

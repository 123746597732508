import { errorMessage, successMessage } from './message';
import locale from '../utils/localization.json';

export const copyTextToClipboard = (text, title) => {
    var textArea = document.createElement('textarea');

    textArea.style.position = 'fixed';
    textArea.style.top = 0;
    textArea.style.left = 0;
    textArea.style.width = '2em';
    textArea.style.height = '2em';
    textArea.style.padding = 0;
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';
    textArea.style.background = 'transparent';

    textArea.textContent = text;

    document.body.appendChild(textArea);
    var selection = document.getSelection();
    var range = document.createRange();
    //  range.selectNodeContents(textArea);
    range.selectNode(textArea);
    selection.removeAllRanges();
    selection.addRange(range);

    const lang = localStorage.getItem('language');

    try {
        var successful = document.execCommand('copy');
        document.body.removeChild(textArea);

        if (successful) successMessage(`${title} ${locale[lang].copySuccess}`);
        else
            errorMessage(
                `${locale[lang].copyUnsuccess1} ${title} ${locale[lang].copyUnsuccess2} `
            );
    } catch (err) {
        errorMessage(
            `${locale[lang].copyUnsuccess1} ${title} ${locale[lang].copyUnsuccess2} `
        );
    }
};

export const uniq = (array) => {
    var prims = { boolean: {}, number: {}, string: {} },
        objs = [];

    return array.filter(function (item) {
        var type = typeof item;
        if (type in prims)
            return prims[type].hasOwnProperty(item)
                ? false
                : (prims[type][item] = true);
        else return objs.indexOf(item) >= 0 ? false : objs.push(item);
    });
};

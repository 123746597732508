import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IcEye, IcObjectName } from './../../share/icons';
import { setCurrentModel } from '../../actions/model';
import locale from '../../utils/localization.json';
import { mainContext } from './../context/mainContext';

const ViewCard = ({ geom, index }) => {
    const lang = useSelector((state) => state.language);
    const viewer = useSelector((state) => state.viewer);
    const currentObject = useSelector((state) => state.currentObject);

    const dispatch = useDispatch();

    const { loadModel } = useContext(mainContext);

    return (
        <div className="w-full pr-4 pl-2 py-4 flex justify-between bg-modelic-gray-800 rounded border border-modelic-gray-700">
            <div className="flex-col inline-flex gap-2">
                <div className="justify-start items-center gap-1.5 inline-flex">
                    <div className="text-modelic-primary-lighter">
                        {IcObjectName}
                    </div>
                    <div className="text-modelic-primary-lighter text-xs font-normal font-poppins leading-3">
                        {locale[lang].name}:
                    </div>
                    <div className="text-modelic-gray-100 text-xs font-medium font-poppins leading-3">
                        {geom.data.name}
                    </div>
                </div>
            </div>
            <div className="grid content-between">
                <div className="justify-self-end"></div>
                <div className="flex gap-4">
                    <button
                        className="text-modelic-primary-lighter"
                        onClick={() => {
                            loadModel(viewer, currentObject, index);
                            const updatedCurrentModel = {
                                ...currentObject,
                            };
                            updatedCurrentModel.viewIndex = index;

                            dispatch(setCurrentModel(updatedCurrentModel));
                        }}
                    >
                        {IcEye}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ViewCard;

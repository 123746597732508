import React from 'react';
import { useSelector } from 'react-redux';

const ViewerNotification = () => {
    const notifications = useSelector((state) => state.notifications);

    return (
        <div className="flex absolute">
            <div className="z-10 ml-2 font-poppins text-modelic-gray-500">
                {notifications?.length > 0 &&
                    notifications?.map((notif, index) => (
                        <p key={notif.id || index} className="mb-0">
                            {notif.value}
                        </p>
                    ))}
            </div>
        </div>
    );
};

export default ViewerNotification;

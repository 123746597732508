import { useContext, useEffect, useState } from 'react';

import IssueAll from './IssueAll';
import IssueNew from './IssueNew';
import { issueContext } from './../context/issueContext';
import { useSelector, useDispatch } from 'react-redux';
import { getAllIssues } from '../../actions/issue';
import { teamColor } from './../../utils/colors';
import IssueSingle from './IssueSingle';
import { uniq } from '../../utils';

const Issue = () => {
    const { issueView } = useContext(issueContext);

    const issues = useSelector((state) => state.issues);

    const [teamsColor, setTeamsColor] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        let teams = issues.map((i) => [i.team, i.assignee]);
        teams = [...teams].flat(1);
        teams = uniq([...teams])
            .filter((t) => t !== null)
            .map((name, i) => {
                return { name, color: teamColor(i) };
            });

        setTeamsColor(teams);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [issues]);

    useEffect(() => {
        if (issueView === 'all') dispatch(getAllIssues());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [issueView]);

    return issueView === 'all' ? (
        <IssueAll teamsColor={teamsColor} />
    ) : issueView === 'new' || issueView === 'edit' || issueView === 'clone' ? (
        <IssueNew />
    ) : issueView === 'show' ? (
        <IssueSingle teamsColor={teamsColor} issue={issues[0]} />
    ) : null;
};

export default Issue;

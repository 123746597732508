import { isEmpty } from 'lodash';

export const setViewer = (viewer) => {
    return async (dispatch) => {
        await dispatch({ type: 'INIT_VIEWER', payload: viewer });
    };
};

export const setGeometries = (geometries) => {
    return async (dispatch) => {
        await dispatch({ type: 'INIT_GEOMETRIES', payload: geometries });
    };
};

export const updateOptions = (updatedOptions) => {
    return async (dispatch) => {
        await dispatch({ type: 'UPDATE_OPTIONS', payload: updatedOptions });
    };
};

export const clearViewer = () => {
    return async (dispatch, getState) => {
        const viewer = getState().viewer;
        if (!isEmpty(viewer)) viewer?.finish();
        await dispatch({ type: 'INIT_VIEWER', payload: null });
        await dispatch({ type: 'INIT_GEOMETRIES', payload: [] });
    };
};

export const clearNotifications = () => {
    return async (dispatch) => {
        await dispatch({ type: 'UPDATE_NOTIFICATION', payload: [] });
    };
};

export const updateNotifications = (id, value) => {
    return async (dispatch, getState) => {
        const notifications = [...getState().notifications];
        const notifIndex = notifications.findIndex((notif) => notif.id === id);

        let updatedNotifications;

        if (value) {
            if (notifIndex >= 0) {
                notifications[notifIndex].value = value;
            } else {
                notifications.push({ id, value });
            }

            updatedNotifications = [...notifications];
        } else {
            updatedNotifications = notifications.filter(
                (notif) => notif.id !== id
            );
        }

        await dispatch({
            type: 'UPDATE_NOTIFICATION',
            payload: updatedNotifications,
        });
    };
};
